/* eslint-disable prettier/prettier */
/* based on https://github.com/joaoeudes7/V-Emoji-Picker */
class Emoji {
  constructor(emoji, description, category, aliases, tags) {
    this.emoji = emoji;
    this.description = description;
    this.category = category;
    this.aliases = aliases;
    this.tags = tags;
  }
}

export default [
  new Emoji("😀", "grinning face", "Peoples", ["grinning"], ["smile","happy"]),
  new Emoji("😃", "smiling face with open mouth", "Peoples", ["smiley"], ["happy","joy","haha"]),
  new Emoji("😄", "smiling face with open mouth & smiling eyes", "Peoples", ["smile"], ["happy","joy","laugh","pleased"]),
  new Emoji("😁", "grinning face with smiling eyes", "Peoples", ["grin"], []),
  new Emoji("😆", "smiling face with open mouth & closed eyes", "Peoples", ["laughing","satisfied"], ["happy","haha"]),
  new Emoji("😅", "smiling face with open mouth & cold sweat", "Peoples", ["sweat_smile"], ["hot"]),
  new Emoji("😂", "face with tears of joy", "Peoples", ["joy"], ["tears"]),
  new Emoji("🤣", "rolling on the floor laughing", "Peoples", ["rofl"], ["lol","laughing"]),
  new Emoji("😌", "smiling face", "Peoples", ["relaxed"], ["blush","pleased"]),
  new Emoji("😊", "smiling face with smiling eyes", "Peoples", ["blush"], ["proud"]),
  new Emoji("😇", "smiling face with halo", "Peoples", ["innocent"], ["angel"]),
  new Emoji("🙂", "slightly smiling face", "Peoples", ["slightly_smiling_face"], []),
  new Emoji("🙃", "upside-down face", "Peoples", ["upside_down_face"], []),
  new Emoji("😉", "winking face", "Peoples", ["wink"], ["flirt"]),
  new Emoji("😌", "relieved face", "Peoples", ["relieved"], ["whew"]),
  new Emoji("😍", "smiling face with heart-eyes", "Peoples", ["heart_eyes"], ["love","crush"]),
  new Emoji("😘", "face blowing a kiss", "Peoples", ["kissing_heart"], ["flirt"]),
  new Emoji("😗", "kissing face", "Peoples", ["kissing"], []),
  new Emoji("😙", "kissing face with smiling eyes", "Peoples", ["kissing_smiling_eyes"], []),
  new Emoji("😚", "kissing face with closed eyes", "Peoples", ["kissing_closed_eyes"], []),
  new Emoji("😋", "face savouring delicious food", "Peoples", ["yum"], ["tongue","lick"]),
  new Emoji("😜", "face with stuck-out tongue & winking eye", "Peoples", ["stuck_out_tongue_winking_eye"], ["prank","silly"]),
  new Emoji("😝", "face with stuck-out tongue & closed eyes", "Peoples", ["stuck_out_tongue_closed_eyes"], ["prank"]),
  new Emoji("😛", "face with stuck-out tongue", "Peoples", ["stuck_out_tongue"], []),
  new Emoji("🤑", "money-mouth face", "Peoples", ["money_mouth_face"], ["rich"]),
  new Emoji("🤗", "hugging face", "Peoples", ["hugs"], []),
  new Emoji("🤓", "nerd face", "Peoples", ["nerd_face"], ["geek","glasses"]),
  new Emoji("😎", "smiling face with sunglasses", "Peoples", ["sunglasses"], ["cool"]),
  new Emoji("🤡", "clown face", "Peoples", ["clown_face"], []),
  new Emoji("🤠", "cowboy hat face", "Peoples", ["cowboy_hat_face"], []),
  new Emoji("😏", "smirking face", "Peoples", ["smirk"], ["smug"]),
  new Emoji("😒", "unamused face", "Peoples", ["unamused"], ["meh"]),
  new Emoji("😞", "disappointed face", "Peoples", ["disappointed"], ["sad"]),
  new Emoji("😔", "pensive face", "Peoples", ["pensive"], []),
  new Emoji("😟", "worried face", "Peoples", ["worried"], ["nervous"]),
  new Emoji("😕", "confused face", "Peoples", ["confused"], []),
  new Emoji("🙁", "slightly frowning face", "Peoples", ["slightly_frowning_face"], []),
  new Emoji("☹️", "frowning face", "Peoples", ["frowning_face"], []),
  new Emoji("😣", "persevering face", "Peoples", ["persevere"], ["struggling"]),
  new Emoji("😖", "confounded face", "Peoples", ["confounded"], []),
  new Emoji("😫", "tired face", "Peoples", ["tired_face"], ["upset","whine"]),
  new Emoji("😩", "weary face", "Peoples", ["weary"], ["tired"]),
  new Emoji("😤", "face with steam from nose", "Peoples", ["triumph"], ["smug"]),
  new Emoji("😠", "angry face", "Peoples", ["angry"], ["mad","annoyed"]),
  new Emoji("😡", "pouting face", "Peoples", ["rage","pout"], ["angry"]),
  new Emoji("😶", "face without mouth", "Peoples", ["no_mouth"], ["mute","silence"]),
  new Emoji("😐", "neutral face", "Peoples", ["neutral_face"], ["meh"]),
  new Emoji("😑", "expressionless face", "Peoples", ["expressionless"], []),
  new Emoji("😯", "hushed face", "Peoples", ["hushed"], ["silence","speechless"]),
  new Emoji("😦", "frowning face with open mouth", "Peoples", ["frowning"], []),
  new Emoji("😧", "anguished face", "Peoples", ["anguished"], ["stunned"]),
  new Emoji("😮", "face with open mouth", "Peoples", ["open_mouth"], ["surprise","impressed","wow"]),
  new Emoji("😲", "astonished face", "Peoples", ["astonished"], ["amazed","gasp"]),
  new Emoji("😵", "dizzy face", "Peoples", ["dizzy_face"], []),
  new Emoji("😳", "flushed face", "Peoples", ["flushed"], []),
  new Emoji("😱", "face screaming in fear", "Peoples", ["scream"], ["horror","shocked"]),
  new Emoji("😨", "fearful face", "Peoples", ["fearful"], ["scared","shocked","oops"]),
  new Emoji("😰", "face with open mouth & cold sweat", "Peoples", ["cold_sweat"], ["nervous"]),
  new Emoji("😢", "crying face", "Peoples", ["cry"], ["sad","tear"]),
  new Emoji("😥", "disappointed but relieved face", "Peoples", ["disappointed_relieved"], ["phew","sweat","nervous"]),
  new Emoji("🤤", "drooling face", "Peoples", ["drooling_face"], []),
  new Emoji("😭", "loudly crying face", "Peoples", ["sob"], ["sad","cry","bawling"]),
  new Emoji("😓", "face with cold sweat", "Peoples", ["sweat"], []),
  new Emoji("😪", "sleepy face", "Peoples", ["sleepy"], ["tired"]),
  new Emoji("😴", "sleeping face", "Peoples", ["sleeping"], ["zzz"]),
  new Emoji("🙄", "face with rolling eyes", "Peoples", ["roll_eyes"], []),
  new Emoji("🤔", "thinking face", "Peoples", ["thinking"], []),
  new Emoji("🤥", "lying face", "Peoples", ["lying_face"], ["liar"]),
  new Emoji("😬", "grimacing face", "Peoples", ["grimacing"], []),
  new Emoji("🤐", "zipper-mouth face", "Peoples", ["zipper_mouth_face"], ["silence","hush"]),
  new Emoji("🤢", "nauseated face", "Peoples", ["nauseated_face"], ["sick","barf","disgusted"]),
  new Emoji("🤧", "sneezing face", "Peoples", ["sneezing_face"], ["achoo","sick"]),
  new Emoji("😷", "face with medical mask", "Peoples", ["mask"], ["sick","ill"]),
  new Emoji("🤒", "face with thermometer", "Peoples", ["face_with_thermometer"], ["sick"]),
  new Emoji("🤕", "face with head-bandage", "Peoples", ["face_with_head_bandage"], ["hurt"]),
  new Emoji("😈", "smiling face with horns", "Peoples", ["smiling_imp"], ["devil","evil","horns"]),
  new Emoji("👿", "angry face with horns", "Peoples", ["imp"], ["angry","devil","evil","horns"]),
  new Emoji("👹", "ogre", "Peoples", ["japanese_ogre"], ["monster"]),
  new Emoji("👺", "goblin", "Peoples", ["japanese_goblin"], []),
  new Emoji("💩", "pile of poo", "Peoples", ["hankey","poop","shit"], ["crap"]),
  new Emoji("👻", "ghost", "Peoples", ["ghost"], ["halloween"]),
  new Emoji("💀", "skull", "Peoples", ["skull"], ["dead","danger","poison"]),
  new Emoji("☠️", "skull and crossbones", "Peoples", ["skull_and_crossbones"], ["danger","pirate"]),
  new Emoji("👽", "alien", "Peoples", ["alien"], ["ufo"]),
  new Emoji("👾", "alien monster", "Peoples", ["space_invader"], ["game","retro"]),
  new Emoji("🤖", "robot face", "Peoples", ["robot"], []),
  new Emoji("🎃", "jack-o-lantern", "Peoples", ["jack_o_lantern"], ["halloween"]),
  new Emoji("😺", "smiling cat face with open mouth", "Peoples", ["smiley_cat"], []),
  new Emoji("😸", "grinning cat face with smiling eyes", "Peoples", ["smile_cat"], []),
  new Emoji("😹", "cat face with tears of joy", "Peoples", ["joy_cat"], []),
  new Emoji("😻", "smiling cat face with heart-eyes", "Peoples", ["heart_eyes_cat"], []),
  new Emoji("😼", "cat face with wry smile", "Peoples", ["smirk_cat"], []),
  new Emoji("😽", "kissing cat face with closed eyes", "Peoples", ["kissing_cat"], []),
  new Emoji("🙀", "weary cat face", "Peoples", ["scream_cat"], ["horror"]),
  new Emoji("😿", "crying cat face", "Peoples", ["crying_cat_face"], ["sad","tear"]),
  new Emoji("😾", "pouting cat face", "Peoples", ["pouting_cat"], []),
  new Emoji("👐", "open hands", "Peoples", ["open_hands"], []),
  new Emoji("🙌", "raising hands", "Peoples", ["raised_hands"], ["hooray"]),
  new Emoji("👏", "clapping hands", "Peoples", ["clap"], ["praise","applause"]),
  new Emoji("🙏", "folded hands", "Peoples", ["pray"], ["please","hope","wish"]),
  new Emoji("🤝", "handshake", "Peoples", ["handshake"], ["deal"]),
  new Emoji("👍", "thumbs up", "Peoples", ["+1","thumbsup"], ["approve","ok"]),
  new Emoji("👎", "thumbs down", "Peoples", ["-1","thumbsdown"], ["disapprove","bury"]),
  new Emoji("👊", "oncoming fist", "Peoples", ["fist_oncoming","facepunch","punch"], ["attack"]),
  new Emoji("✊", "raised fist", "Peoples", ["fist_raised","fist"], ["power"]),
  new Emoji("🤛", "left-facing fist", "Peoples", ["fist_left"], []),
  new Emoji("🤜", "right-facing fist", "Peoples", ["fist_right"], []),
  new Emoji("🤞", "crossed fingers", "Peoples", ["crossed_fingers"], ["luck","hopeful"]),
  new Emoji("✌️", "victory hand", "Peoples", ["v"], ["victory","peace"]),
  new Emoji("🤘", "sign of the horns", "Peoples", ["metal"], []),
  new Emoji("👌", "OK hand", "Peoples", ["ok_hand"], []),
  new Emoji("👈", "backhand index pointing left", "Peoples", ["point_left"], []),
  new Emoji("👉", "backhand index pointing right", "Peoples", ["point_right"], []),
  new Emoji("👆", "backhand index pointing up", "Peoples", ["point_up_2"], []),
  new Emoji("👇", "backhand index pointing down", "Peoples", ["point_down"], []),
  new Emoji("☝️", "index pointing up", "Peoples", ["point_up"], []),
  new Emoji("✋", "raised hand", "Peoples", ["hand","raised_hand"], ["highfive","stop"]),
  new Emoji("🤚", "raised back of hand", "Peoples", ["raised_back_of_hand"], []),
  new Emoji("🖐", "raised hand with fingers splayed", "Peoples", ["raised_hand_with_fingers_splayed"], []),
  new Emoji("🖖", "vulcan salute", "Peoples", ["vulcan_salute"], ["prosper","spock"]),
  new Emoji("👋", "waving hand", "Peoples", ["wave"], ["goodbye"]),
  new Emoji("🤙", "call me hand", "Peoples", ["call_me_hand"], []),
  new Emoji("💪", "flexed biceps", "Peoples", ["muscle"], ["flex","bicep","strong","workout"]),
  new Emoji("🖕", "middle finger", "Peoples", ["middle_finger","fu"], []),
  new Emoji("✍️", "writing hand", "Peoples", ["writing_hand"], []),
  new Emoji("🤳", "selfie", "Peoples", ["selfie"], []),
  new Emoji("💅", "nail polish", "Peoples", ["nail_care"], ["beauty","manicure"]),
  new Emoji("💍", "ring", "Peoples", ["ring"], ["wedding","marriage","engaged"]),
  new Emoji("💄", "lipstick", "Peoples", ["lipstick"], ["makeup"]),
  new Emoji("💋", "kiss mark", "Peoples", ["kiss"], ["lipstick"]),
  new Emoji("👄", "mouth", "Peoples", ["lips"], ["kiss"]),
  new Emoji("👅", "tongue", "Peoples", ["tongue"], ["taste"]),
  new Emoji("👂", "ear", "Peoples", ["ear"], ["hear","sound","listen"]),
  new Emoji("👃", "nose", "Peoples", ["nose"], ["smell"]),
  new Emoji("👣", "footprints", "Peoples", ["footprints"], ["feet","tracks"]),
  new Emoji("👁", "eye", "Peoples", ["eye"], []),
  new Emoji("👀", "eyes", "Peoples", ["eyes"], ["look","see","watch"]),
  new Emoji("🗣", "speaking head", "Peoples", ["speaking_head"], []),
  new Emoji("👤", "bust in silhouette", "Peoples", ["bust_in_silhouette"], ["user"]),
  new Emoji("👥", "busts in silhouette", "Peoples", ["busts_in_silhouette"], ["users","group","team"]),
  new Emoji("👶", "baby", "Peoples", ["baby"], ["child","newborn"]),
  new Emoji("👦", "boy", "Peoples", ["boy"], ["child"]),
  new Emoji("👧", "girl", "Peoples", ["girl"], ["child"]),
  new Emoji("👨", "man", "Peoples", ["man"], ["mustache","father","dad"]),
  new Emoji("👩", "woman", "Peoples", ["woman"], ["girls"]),
  new Emoji("👱‍♀", "blond-haired woman", "Peoples", ["blonde_woman"], []),
  new Emoji("👱", "blond-haired person", "Peoples", ["blonde_man","person_with_blond_hair"], ["boy"]),
  new Emoji("👴", "old man", "Peoples", ["older_man"], []),
  new Emoji("👵", "old woman", "Peoples", ["older_woman"], []),
  new Emoji("👲", "man with Chinese cap", "Peoples", ["man_with_gua_pi_mao"], []),
  new Emoji("👳‍♀", "woman wearing turban", "Peoples", ["woman_with_turban"], []),
  new Emoji("👳", "person wearing turban", "Peoples", ["man_with_turban"], []),
  new Emoji("👮‍♀", "woman police officer", "Peoples", ["policewoman"], []),
  new Emoji("👮", "police officer", "Peoples", ["policeman","cop"], ["police","law"]),
  new Emoji("👷‍♀", "woman construction worker", "Peoples", ["construction_worker_woman"], []),
  new Emoji("👷", "construction worker", "Peoples", ["construction_worker_man","construction_worker"], ["helmet"]),
  new Emoji("💂‍♀", "woman guard", "Peoples", ["guardswoman"], []),
  new Emoji("💂", "guard", "Peoples", ["guardsman"], []),
  new Emoji("👩‍⚕", "woman health worker", "Peoples", ["woman_health_worker"], ["doctor","nurse"]),
  new Emoji("👨‍⚕", "man health worker", "Peoples", ["man_health_worker"], ["doctor","nurse"]),
  new Emoji("👩‍🌾", "woman farmer", "Peoples", ["woman_farmer"], []),
  new Emoji("👨‍🌾", "man farmer", "Peoples", ["man_farmer"], []),
  new Emoji("👩‍🍳", "woman cook", "Peoples", ["woman_cook"], ["chef"]),
  new Emoji("👨‍🍳", "man cook", "Peoples", ["man_cook"], ["chef"]),
  new Emoji("👩‍🎓", "woman student", "Peoples", ["woman_student"], ["graduation"]),
  new Emoji("👨‍🎓", "man student", "Peoples", ["man_student"], ["graduation"]),
  new Emoji("👩‍🎤", "woman singer", "Peoples", ["woman_singer"], ["rockstar"]),
  new Emoji("👨‍🎤", "man singer", "Peoples", ["man_singer"], ["rockstar"]),
  new Emoji("👩‍🏫", "woman teacher", "Peoples", ["woman_teacher"], ["school","professor"]),
  new Emoji("👨‍🏫", "man teacher", "Peoples", ["man_teacher"], ["school","professor"]),
  new Emoji("👩‍🏭", "woman factory worker", "Peoples", ["woman_factory_worker"], []),
  new Emoji("👨‍🏭", "man factory worker", "Peoples", ["man_factory_worker"], []),
  new Emoji("👩‍💻", "woman technologist", "Peoples", ["woman_technologist"], ["coder"]),
  new Emoji("👨‍💻", "man technologist", "Peoples", ["man_technologist"], ["coder"]),
  new Emoji("👩‍💼", "woman office worker", "Peoples", ["woman_office_worker"], ["business"]),
  new Emoji("👨‍💼", "man office worker", "Peoples", ["man_office_worker"], ["business"]),
  new Emoji("👩‍🔧", "woman mechanic", "Peoples", ["woman_mechanic"], []),
  new Emoji("👨‍🔧", "man mechanic", "Peoples", ["man_mechanic"], []),
  new Emoji("👩‍🔬", "woman scientist", "Peoples", ["woman_scientist"], ["research"]),
  new Emoji("👨‍🔬", "man scientist", "Peoples", ["man_scientist"], ["research"]),
  new Emoji("👩‍🎨", "woman artist", "Peoples", ["woman_artist"], ["painter"]),
  new Emoji("👨‍🎨", "man artist", "Peoples", ["man_artist"], ["painter"]),
  new Emoji("👩‍🚒", "woman firefighter", "Peoples", ["woman_firefighter"], []),
  new Emoji("👨‍🚒", "man firefighter", "Peoples", ["man_firefighter"], []),
  new Emoji("👩‍🚀", "woman astronaut", "Peoples", ["woman_astronaut"], ["space"]),
  new Emoji("👨‍🚀", "man astronaut", "Peoples", ["man_astronaut"], ["space"]),
  new Emoji("🤶", "Mrs. Claus", "Peoples", ["mrs_claus"], ["santa"]),
  new Emoji("🎅", "Santa Claus", "Peoples", ["santa"], ["christmas"]),
  new Emoji("👸", "princess", "Peoples", ["princess"], ["blonde","crown","royal"]),
  new Emoji("🤴", "prince", "Peoples", ["prince"], ["crown","royal"]),
  new Emoji("👰", "bride with veil", "Peoples", ["bride_with_veil"], ["marriage","wedding"]),
  new Emoji("🤵", "man in tuxedo", "Peoples", ["man_in_tuxedo"], ["groom","marriage","wedding"]),
  new Emoji("👼", "baby angel", "Peoples", ["angel"], []),
  new Emoji("🤰", "pregnant woman", "Peoples", ["pregnant_woman"], []),
  new Emoji("🙇‍♀", "woman bowing", "Peoples", ["bowing_woman"], ["respect","thanks"]),
  new Emoji("🙇", "person bowing", "Peoples", ["bowing_man","bow"], ["respect","thanks"]),
  new Emoji("💁", "person tipping hand", "Peoples", ["tipping_hand_woman","information_desk_person","sassy_woman"], []),
  new Emoji("💁‍♂", "man tipping hand", "Peoples", ["tipping_hand_man","sassy_man"], ["information"]),
  new Emoji("🙅", "person gesturing NO", "Peoples", ["no_good_woman","no_good","ng_woman"], ["stop","halt"]),
  new Emoji("🙅‍♂", "man gesturing NO", "Peoples", ["no_good_man","ng_man"], ["stop","halt"]),
  new Emoji("🙆", "person gesturing OK", "Peoples", ["ok_woman"], []),
  new Emoji("🙆‍♂", "man gesturing OK", "Peoples", ["ok_man"], []),
  new Emoji("🙋", "person raising hand", "Peoples", ["raising_hand_woman","raising_hand"], []),
  new Emoji("🙋‍♂", "man raising hand", "Peoples", ["raising_hand_man"], []),
  new Emoji("🤦‍♀", "woman facepalming", "Peoples", ["woman_facepalming"], []),
  new Emoji("🤦‍♂", "man facepalming", "Peoples", ["man_facepalming"], []),
  new Emoji("🤷‍♀", "woman shrugging", "Peoples", ["woman_shrugging"], []),
  new Emoji("🤷‍♂", "man shrugging", "Peoples", ["man_shrugging"], []),
  new Emoji("🙎", "person pouting", "Peoples", ["pouting_woman","person_with_pouting_face"], []),
  new Emoji("🙎‍♂", "man pouting", "Peoples", ["pouting_man"], []),
  new Emoji("🙍", "person frowning", "Peoples", ["frowning_woman","person_frowning"], ["sad"]),
  new Emoji("🙍‍♂", "man frowning", "Peoples", ["frowning_man"], []),
  new Emoji("💇", "person getting haircut", "Peoples", ["haircut_woman","haircut"], ["beauty"]),
  new Emoji("💇‍♂", "man getting haircut", "Peoples", ["haircut_man"], []),
  new Emoji("💆", "person getting massage", "Peoples", ["massage_woman","massage"], ["spa"]),
  new Emoji("💆‍♂", "man getting massage", "Peoples", ["massage_man"], ["spa"]),
  new Emoji("🕴", "man in business suit levitating", "Peoples", ["business_suit_levitating"], []),
  new Emoji("💃", "woman dancing", "Peoples", ["dancer"], ["dress"]),
  new Emoji("🕺", "man dancing", "Peoples", ["man_dancing"], ["dancer"]),
  new Emoji("👯", "people with bunny ears partying", "Peoples", ["dancing_women","dancers"], ["bunny"]),
  new Emoji("👯‍♂", "men with bunny ears partying", "Peoples", ["dancing_men"], ["bunny"]),
  new Emoji("🚶‍♀", "woman walking", "Peoples", ["walking_woman"], []),
  new Emoji("🚶", "person walking", "Peoples", ["walking_man","walking"], []),
  new Emoji("🏃‍♀", "woman running", "Peoples", ["running_woman"], ["exercise","workout","marathon"]),
  new Emoji("🏃", "person running", "Peoples", ["running_man","runner","running"], ["exercise","workout","marathon"]),
  new Emoji("👫", "man and woman holding hands", "Peoples", ["couple"], ["date"]),
  new Emoji("👭", "two women holding hands", "Peoples", ["two_women_holding_hands"], ["couple","date"]),
  new Emoji("👬", "two men holding hands", "Peoples", ["two_men_holding_hands"], ["couple","date"]),
  new Emoji("💑", "couple with heart", "Peoples", ["couple_with_heart_woman_man","couple_with_heart"], []),
  new Emoji("👩‍❤️‍👩", "couple with heart: woman, woman", "Peoples", ["couple_with_heart_woman_woman"], []),
  new Emoji("👨‍❤️‍👨", "couple with heart: man, man", "Peoples", ["couple_with_heart_man_man"], []),
  new Emoji("💏", "kiss", "Peoples", ["couplekiss_man_woman"], []),
  new Emoji("👩‍❤️‍💋‍👩", "kiss: woman, woman", "Peoples", ["couplekiss_woman_woman"], []),
  new Emoji("👨‍❤️‍💋‍👨", "kiss: man, man", "Peoples", ["couplekiss_man_man"], []),
  new Emoji("👪", "family", "Peoples", ["family_man_woman_boy","family"], ["home","parents","child"]),
  new Emoji("👨‍👩‍👧", "family: man, woman, girl", "Peoples", ["family_man_woman_girl"], []),
  new Emoji("👨‍👩‍👧‍👦", "family: man, woman, girl, boy", "Peoples", ["family_man_woman_girl_boy"], []),
  new Emoji("👨‍👩‍👦‍👦", "family: man, woman, boy, boy", "Peoples", ["family_man_woman_boy_boy"], []),
  new Emoji("👨‍👩‍👧‍👧", "family: man, woman, girl, girl", "Peoples", ["family_man_woman_girl_girl"], []),
  new Emoji("👩‍👩‍👦", "family: woman, woman, boy", "Peoples", ["family_woman_woman_boy"], []),
  new Emoji("👩‍👩‍👧", "family: woman, woman, girl", "Peoples", ["family_woman_woman_girl"], []),
  new Emoji("👩‍👩‍👧‍👦", "family: woman, woman, girl, boy", "Peoples", ["family_woman_woman_girl_boy"], []),
  new Emoji("👩‍👩‍👦‍👦", "family: woman, woman, boy, boy", "Peoples", ["family_woman_woman_boy_boy"], []),
  new Emoji("👩‍👩‍👧‍👧", "family: woman, woman, girl, girl", "Peoples", ["family_woman_woman_girl_girl"], []),
  new Emoji("👨‍👨‍👦", "family: man, man, boy", "Peoples", ["family_man_man_boy"], []),
  new Emoji("👨‍👨‍👧", "family: man, man, girl", "Peoples", ["family_man_man_girl"], []),
  new Emoji("👨‍👨‍👧‍👦", "family: man, man, girl, boy", "Peoples", ["family_man_man_girl_boy"], []),
  new Emoji("👨‍👨‍👦‍👦", "family: man, man, boy, boy", "Peoples", ["family_man_man_boy_boy"], []),
  new Emoji("👨‍👨‍👧‍👧", "family: man, man, girl, girl", "Peoples", ["family_man_man_girl_girl"], []),
  new Emoji("👩‍👦", "family: woman, boy", "Peoples", ["family_woman_boy"], []),
  new Emoji("👩‍👧", "family: woman, girl", "Peoples", ["family_woman_girl"], []),
  new Emoji("👩‍👧‍👦", "family: woman, girl, boy", "Peoples", ["family_woman_girl_boy"], []),
  new Emoji("👩‍👦‍👦", "family: woman, boy, boy", "Peoples", ["family_woman_boy_boy"], []),
  new Emoji("👩‍👧‍👧", "family: woman, girl, girl", "Peoples", ["family_woman_girl_girl"], []),
  new Emoji("👨‍👦", "family: man, boy", "Peoples", ["family_man_boy"], []),
  new Emoji("👨‍👧", "family: man, girl", "Peoples", ["family_man_girl"], []),
  new Emoji("👨‍👧‍👦", "family: man, girl, boy", "Peoples", ["family_man_girl_boy"], []),
  new Emoji("👨‍👦‍👦", "family: man, boy, boy", "Peoples", ["family_man_boy_boy"], []),
  new Emoji("👨‍👧‍👧", "family: man, girl, girl", "Peoples", ["family_man_girl_girl"], []),
  new Emoji("👚", "woman’s clothes", "Peoples", ["womans_clothes"], []),
  new Emoji("👕", "t-shirt", "Peoples", ["shirt","tshirt"], []),
  new Emoji("👖", "jeans", "Peoples", ["jeans"], ["pants"]),
  new Emoji("👔", "necktie", "Peoples", ["necktie"], ["shirt","formal"]),
  new Emoji("👗", "dress", "Peoples", ["dress"], []),
  new Emoji("👙", "bikini", "Peoples", ["bikini"], ["beach"]),
  new Emoji("👘", "kimono", "Peoples", ["kimono"], []),
  new Emoji("👠", "high-heeled shoe", "Peoples", ["high_heel"], ["shoe"]),
  new Emoji("👡", "woman’s sandal", "Peoples", ["sandal"], ["shoe"]),
  new Emoji("👢", "woman’s boot", "Peoples", ["boot"], []),
  new Emoji("👞", "man’s shoe", "Peoples", ["mans_shoe","shoe"], []),
  new Emoji("👟", "running shoe", "Peoples", ["athletic_shoe"], ["sneaker","sport","running"]),
  new Emoji("👒", "woman’s hat", "Peoples", ["womans_hat"], []),
  new Emoji("🎩", "top hat", "Peoples", ["tophat"], ["hat","classy"]),
  new Emoji("🎓", "graduation cap", "Peoples", ["mortar_board"], ["education","college","university","graduation"]),
  new Emoji("👑", "crown", "Peoples", ["crown"], ["king","queen","royal"]),
  new Emoji("⛑", "rescue worker’s helmet", "Peoples", ["rescue_worker_helmet"], []),
  new Emoji("🎒", "school backpack", "Peoples", ["school_satchel"], []),
  new Emoji("👝", "clutch bag", "Peoples", ["pouch"], ["bag"]),
  new Emoji("👛", "purse", "Peoples", ["purse"], []),
  new Emoji("👜", "handbag", "Peoples", ["handbag"], ["bag"]),
  new Emoji("💼", "briefcase", "Peoples", ["briefcase"], ["business"]),
  new Emoji("👓", "glasses", "Peoples", ["eyeglasses"], ["glasses"]),
  new Emoji("🕶", "sunglasses", "Peoples", ["dark_sunglasses"], []),
  new Emoji("🌂", "closed umbrella", "Peoples", ["closed_umbrella"], ["weather","rain"]),
  new Emoji("☂️", "umbrella", "Peoples", ["open_umbrella"], []),
  new Emoji("🐶", "dog face", "Nature", ["dog"], ["pet"]),
  new Emoji("🐱", "cat face", "Nature", ["cat"], ["pet"]),
  new Emoji("🐭", "mouse face", "Nature", ["mouse"], []),
  new Emoji("🐹", "hamster face", "Nature", ["hamster"], ["pet"]),
  new Emoji("🐰", "rabbit face", "Nature", ["rabbit"], ["bunny"]),
  new Emoji("🦊", "fox face", "Nature", ["fox_face"], []),
  new Emoji("🐻", "bear face", "Nature", ["bear"], []),
  new Emoji("🐼", "panda face", "Nature", ["panda_face"], []),
  new Emoji("🐨", "koala", "Nature", ["koala"], []),
  new Emoji("🐯", "tiger face", "Nature", ["tiger"], []),
  new Emoji("🦁", "lion face", "Nature", ["lion"], []),
  new Emoji("🐮", "cow face", "Nature", ["cow"], []),
  new Emoji("🐷", "pig face", "Nature", ["pig"], []),
  new Emoji("🐽", "pig nose", "Nature", ["pig_nose"], []),
  new Emoji("🐸", "frog face", "Nature", ["frog"], []),
  new Emoji("🐵", "monkey face", "Nature", ["monkey_face"], []),
  new Emoji("🙈", "see-no-evil monkey", "Nature", ["see_no_evil"], ["monkey","blind","ignore"]),
  new Emoji("🙉", "hear-no-evil monkey", "Nature", ["hear_no_evil"], ["monkey","deaf"]),
  new Emoji("🙊", "speak-no-evil monkey", "Nature", ["speak_no_evil"], ["monkey","mute","hush"]),
  new Emoji("🐒", "monkey", "Nature", ["monkey"], []),
  new Emoji("🐔", "chicken", "Nature", ["chicken"], []),
  new Emoji("🐧", "penguin", "Nature", ["penguin"], []),
  new Emoji("🐦", "bird", "Nature", ["bird"], []),
  new Emoji("🐤", "baby chick", "Nature", ["baby_chick"], []),
  new Emoji("🐣", "hatching chick", "Nature", ["hatching_chick"], []),
  new Emoji("🐥", "front-facing baby chick", "Nature", ["hatched_chick"], []),
  new Emoji("🦆", "duck", "Nature", ["duck"], []),
  new Emoji("🦅", "eagle", "Nature", ["eagle"], []),
  new Emoji("🦉", "owl", "Nature", ["owl"], []),
  new Emoji("🦇", "bat", "Nature", ["bat"], []),
  new Emoji("🐺", "wolf face", "Nature", ["wolf"], []),
  new Emoji("🐗", "boar", "Nature", ["boar"], []),
  new Emoji("🐴", "horse face", "Nature", ["horse"], []),
  new Emoji("🦄", "unicorn face", "Nature", ["unicorn"], []),
  new Emoji("🐝", "honeybee", "Nature", ["bee","honeybee"], []),
  new Emoji("🐛", "bug", "Nature", ["bug"], []),
  new Emoji("🦋", "butterfly", "Nature", ["butterfly"], []),
  new Emoji("🐌", "snail", "Nature", ["snail"], ["slow"]),
  new Emoji("🐚", "spiral shell", "Nature", ["shell"], ["sea","beach"]),
  new Emoji("🐞", "lady beetle", "Nature", ["beetle"], ["bug"]),
  new Emoji("🐜", "ant", "Nature", ["ant"], []),
  new Emoji("🕷", "spider", "Nature", ["spider"], []),
  new Emoji("🕸", "spider web", "Nature", ["spider_web"], []),
  new Emoji("🐢", "turtle", "Nature", ["turtle"], ["slow"]),
  new Emoji("🐍", "snake", "Nature", ["snake"], []),
  new Emoji("🦎", "lizard", "Nature", ["lizard"], []),
  new Emoji("🦂", "scorpion", "Nature", ["scorpion"], []),
  new Emoji("🦀", "crab", "Nature", ["crab"], []),
  new Emoji("🦑", "squid", "Nature", ["squid"], []),
  new Emoji("🐙", "octopus", "Nature", ["octopus"], []),
  new Emoji("🦐", "shrimp", "Nature", ["shrimp"], []),
  new Emoji("🐠", "tropical fish", "Nature", ["tropical_fish"], []),
  new Emoji("🐟", "fish", "Nature", ["fish"], []),
  new Emoji("🐡", "blowfish", "Nature", ["blowfish"], []),
  new Emoji("🐬", "dolphin", "Nature", ["dolphin","flipper"], []),
  new Emoji("🦈", "shark", "Nature", ["shark"], []),
  new Emoji("🐳", "spouting whale", "Nature", ["whale"], ["sea"]),
  new Emoji("🐋", "whale", "Nature", ["whale2"], []),
  new Emoji("🐊", "crocodile", "Nature", ["crocodile"], []),
  new Emoji("🐆", "leopard", "Nature", ["leopard"], []),
  new Emoji("🐅", "tiger", "Nature", ["tiger2"], []),
  new Emoji("🐃", "water buffalo", "Nature", ["water_buffalo"], []),
  new Emoji("🐂", "ox", "Nature", ["ox"], []),
  new Emoji("🐄", "cow", "Nature", ["cow2"], []),
  new Emoji("🦌", "deer", "Nature", ["deer"], []),
  new Emoji("🐪", "camel", "Nature", ["dromedary_camel"], ["desert"]),
  new Emoji("🐫", "two-hump camel", "Nature", ["camel"], []),
  new Emoji("🐘", "elephant", "Nature", ["elephant"], []),
  new Emoji("🦏", "rhinoceros", "Nature", ["rhinoceros"], []),
  new Emoji("🦍", "gorilla", "Nature", ["gorilla"], []),
  new Emoji("🐎", "horse", "Nature", ["racehorse"], ["speed"]),
  new Emoji("🐖", "pig", "Nature", ["pig2"], []),
  new Emoji("🐐", "goat", "Nature", ["goat"], []),
  new Emoji("🐏", "ram", "Nature", ["ram"], []),
  new Emoji("🐑", "sheep", "Nature", ["sheep"], []),
  new Emoji("🐕", "dog", "Nature", ["dog2"], []),
  new Emoji("🐩", "poodle", "Nature", ["poodle"], ["dog"]),
  new Emoji("🐈", "cat", "Nature", ["cat2"], []),
  new Emoji("🐓", "rooster", "Nature", ["rooster"], []),
  new Emoji("🦃", "turkey", "Nature", ["turkey"], ["thanksgiving"]),
  new Emoji("🕊", "dove", "Nature", ["dove"], ["peace"]),
  new Emoji("🐇", "rabbit", "Nature", ["rabbit2"], []),
  new Emoji("🐁", "mouse", "Nature", ["mouse2"], []),
  new Emoji("🐀", "rat", "Nature", ["rat"], []),
  new Emoji("🐿", "chipmunk", "Nature", ["chipmunk"], []),
  new Emoji("🐾", "paw prints", "Nature", ["feet","paw_prints"], []),
  new Emoji("🐉", "dragon", "Nature", ["dragon"], []),
  new Emoji("🐲", "dragon face", "Nature", ["dragon_face"], []),
  new Emoji("🌵", "cactus", "Nature", ["cactus"], []),
  new Emoji("🎄", "Christmas tree", "Nature", ["christmas_tree"], []),
  new Emoji("🌲", "evergreen tree", "Nature", ["evergreen_tree"], ["wood"]),
  new Emoji("🌳", "deciduous tree", "Nature", ["deciduous_tree"], ["wood"]),
  new Emoji("🌴", "palm tree", "Nature", ["palm_tree"], []),
  new Emoji("🌱", "seedling", "Nature", ["seedling"], ["plant"]),
  new Emoji("🌿", "herb", "Nature", ["herb"], []),
  new Emoji("☘️", "shamrock", "Nature", ["shamrock"], []),
  new Emoji("🍀", "four leaf clover", "Nature", ["four_leaf_clover"], ["luck"]),
  new Emoji("🎍", "pine decoration", "Nature", ["bamboo"], []),
  new Emoji("🎋", "tanabata tree", "Nature", ["tanabata_tree"], []),
  new Emoji("🍃", "leaf fluttering in wind", "Nature", ["leaves"], ["leaf"]),
  new Emoji("🍂", "fallen leaf", "Nature", ["fallen_leaf"], ["autumn"]),
  new Emoji("🍁", "maple leaf", "Nature", ["maple_leaf"], ["canada"]),
  new Emoji("🍄", "mushroom", "Nature", ["mushroom"], []),
  new Emoji("🌾", "sheaf of rice", "Nature", ["ear_of_rice"], []),
  new Emoji("💐", "bouquet", "Nature", ["bouquet"], ["flowers"]),
  new Emoji("🌷", "tulip", "Nature", ["tulip"], ["flower"]),
  new Emoji("🌹", "rose", "Nature", ["rose"], ["flower"]),
  new Emoji("🥀", "wilted flower", "Nature", ["wilted_flower"], []),
  new Emoji("🌻", "sunflower", "Nature", ["sunflower"], []),
  new Emoji("🌼", "blossom", "Nature", ["blossom"], []),
  new Emoji("🌸", "cherry blossom", "Nature", ["cherry_blossom"], ["flower","spring"]),
  new Emoji("🌺", "hibiscus", "Nature", ["hibiscus"], []),
  new Emoji("🌎", "globe showing Americas", "Nature", ["earth_americas"], ["globe","world","international"]),
  new Emoji("🌍", "globe showing Europe-Africa", "Nature", ["earth_africa"], ["globe","world","international"]),
  new Emoji("🌏", "globe showing Asia-Australia", "Nature", ["earth_asia"], ["globe","world","international"]),
  new Emoji("🌕", "full moon", "Nature", ["full_moon"], []),
  new Emoji("🌖", "waning gibbous moon", "Nature", ["waning_gibbous_moon"], []),
  new Emoji("🌗", "last quarter moon", "Nature", ["last_quarter_moon"], []),
  new Emoji("🌘", "waning crescent moon", "Nature", ["waning_crescent_moon"], []),
  new Emoji("🌑", "new moon", "Nature", ["new_moon"], []),
  new Emoji("🌒", "waxing crescent moon", "Nature", ["waxing_crescent_moon"], []),
  new Emoji("🌓", "first quarter moon", "Nature", ["first_quarter_moon"], []),
  new Emoji("🌔", "waxing gibbous moon", "Nature", ["moon","waxing_gibbous_moon"], []),
  new Emoji("🌚", "new moon face", "Nature", ["new_moon_with_face"], []),
  new Emoji("🌝", "full moon with face", "Nature", ["full_moon_with_face"], []),
  new Emoji("🌞", "sun with face", "Nature", ["sun_with_face"], ["summer"]),
  new Emoji("🌛", "first quarter moon with face", "Nature", ["first_quarter_moon_with_face"], []),
  new Emoji("🌜", "last quarter moon with face", "Nature", ["last_quarter_moon_with_face"], []),
  new Emoji("🌙", "crescent moon", "Nature", ["crescent_moon"], ["night"]),
  new Emoji("💫", "dizzy", "Nature", ["dizzy"], ["star"]),
  new Emoji("⭐️", "white medium star", "Nature", ["star"], []),
  new Emoji("🌟", "glowing star", "Nature", ["star2"], []),
  new Emoji("✨", "sparkles", "Nature", ["sparkles"], ["shiny"]),
  new Emoji("⚡️", "high voltage", "Nature", ["zap"], ["lightning","thunder"]),
  new Emoji("🔥", "fire", "Nature", ["fire"], ["burn"]),
  new Emoji("💥", "collision", "Nature", ["boom","collision"], ["explode"]),
  new Emoji("☄", "comet", "Nature", ["comet"], []),
  new Emoji("☀️", "sun", "Nature", ["sunny"], ["weather"]),
  new Emoji("🌤", "sun behind small cloud", "Nature", ["sun_behind_small_cloud"], []),
  new Emoji("⛅️", "sun behind cloud", "Nature", ["partly_sunny"], ["weather","cloud"]),
  new Emoji("🌥", "sun behind large cloud", "Nature", ["sun_behind_large_cloud"], []),
  new Emoji("🌦", "sun behind rain cloud", "Nature", ["sun_behind_rain_cloud"], []),
  new Emoji("🌈", "rainbow", "Nature", ["rainbow"], []),
  new Emoji("☁️", "cloud", "Nature", ["cloud"], []),
  new Emoji("🌧", "cloud with rain", "Nature", ["cloud_with_rain"], []),
  new Emoji("⛈", "cloud with lightning and rain", "Nature", ["cloud_with_lightning_and_rain"], []),
  new Emoji("🌩", "cloud with lightning", "Nature", ["cloud_with_lightning"], []),
  new Emoji("🌨", "cloud with snow", "Nature", ["cloud_with_snow"], []),
  new Emoji("☃️", "snowman", "Nature", ["snowman_with_snow"], ["winter","christmas"]),
  new Emoji("⛄️", "snowman without snow", "Nature", ["snowman"], ["winter"]),
  new Emoji("❄️", "snowflake", "Nature", ["snowflake"], ["winter","cold","weather"]),
  new Emoji("🌬", "wind face", "Nature", ["wind_face"], []),
  new Emoji("💨", "dashing away", "Nature", ["dash"], ["wind","blow","fast"]),
  new Emoji("🌪", "tornado", "Nature", ["tornado"], []),
  new Emoji("🌫", "fog", "Nature", ["fog"], []),
  new Emoji("🌊", "water wave", "Nature", ["ocean"], ["sea"]),
  new Emoji("💧", "droplet", "Nature", ["droplet"], ["water"]),
  new Emoji("💦", "sweat droplets", "Nature", ["sweat_drops"], ["water","workout"]),
  new Emoji("☔️", "umbrella with rain drops", "Nature", ["umbrella"], ["rain","weather"]),
  new Emoji("🍏", "green apple", "Foods", ["green_apple"], ["fruit"]),
  new Emoji("🍎", "red apple", "Foods", ["apple"], []),
  new Emoji("🍐", "pear", "Foods", ["pear"], []),
  new Emoji("🍊", "tangerine", "Foods", ["tangerine","orange","mandarin"], []),
  new Emoji("🍋", "lemon", "Foods", ["lemon"], []),
  new Emoji("🍌", "banana", "Foods", ["banana"], ["fruit"]),
  new Emoji("🍉", "watermelon", "Foods", ["watermelon"], []),
  new Emoji("🍇", "grapes", "Foods", ["grapes"], []),
  new Emoji("🍓", "strawberry", "Foods", ["strawberry"], ["fruit"]),
  new Emoji("🍈", "melon", "Foods", ["melon"], []),
  new Emoji("🍒", "cherries", "Foods", ["cherries"], ["fruit"]),
  new Emoji("🍑", "peach", "Foods", ["peach"], []),
  new Emoji("🍍", "pineapple", "Foods", ["pineapple"], []),
  new Emoji("🥝", "kiwi fruit", "Foods", ["kiwi_fruit"], []),
  new Emoji("🥑", "avocado", "Foods", ["avocado"], []),
  new Emoji("🍅", "tomato", "Foods", ["tomato"], []),
  new Emoji("🍆", "eggplant", "Foods", ["eggplant"], ["aubergine"]),
  new Emoji("🥒", "cucumber", "Foods", ["cucumber"], []),
  new Emoji("🥕", "carrot", "Foods", ["carrot"], []),
  new Emoji("🌽", "ear of corn", "Foods", ["corn"], []),
  new Emoji("🌶", "hot pepper", "Foods", ["hot_pepper"], ["spicy"]),
  new Emoji("🥔", "potato", "Foods", ["potato"], []),
  new Emoji("🍠", "roasted sweet potato", "Foods", ["sweet_potato"], []),
  new Emoji("🌰", "chestnut", "Foods", ["chestnut"], []),
  new Emoji("🥜", "peanuts", "Foods", ["peanuts"], []),
  new Emoji("🍯", "honey pot", "Foods", ["honey_pot"], []),
  new Emoji("🥐", "croissant", "Foods", ["croissant"], []),
  new Emoji("🍞", "bread", "Foods", ["bread"], ["toast"]),
  new Emoji("🥖", "baguette bread", "Foods", ["baguette_bread"], []),
  new Emoji("🧀", "cheese wedge", "Foods", ["cheese"], []),
  new Emoji("🥚", "egg", "Foods", ["egg"], []),
  new Emoji("🍳", "cooking", "Foods", ["fried_egg"], ["breakfast"]),
  new Emoji("🥓", "bacon", "Foods", ["bacon"], []),
  new Emoji("🥞", "pancakes", "Foods", ["pancakes"], []),
  new Emoji("🍤", "fried shrimp", "Foods", ["fried_shrimp"], ["tempura"]),
  new Emoji("🍗", "poultry leg", "Foods", ["poultry_leg"], ["meat","chicken"]),
  new Emoji("🍖", "meat on bone", "Foods", ["meat_on_bone"], []),
  new Emoji("🍕", "pizza", "Foods", ["pizza"], []),
  new Emoji("🌭", "hot dog", "Foods", ["hotdog"], []),
  new Emoji("🍔", "hamburger", "Foods", ["hamburger"], ["burger"]),
  new Emoji("🍟", "french fries", "Foods", ["fries"], []),
  new Emoji("🥙", "stuffed flatbread", "Foods", ["stuffed_flatbread"], []),
  new Emoji("🌮", "taco", "Foods", ["taco"], []),
  new Emoji("🌯", "burrito", "Foods", ["burrito"], []),
  new Emoji("🥗", "green salad", "Foods", ["green_salad"], []),
  new Emoji("🥘", "shallow pan of food", "Foods", ["shallow_pan_of_food"], ["paella","curry"]),
  new Emoji("🍝", "spaghetti", "Foods", ["spaghetti"], ["pasta"]),
  new Emoji("🍜", "steaming bowl", "Foods", ["ramen"], ["noodle"]),
  new Emoji("🍲", "pot of food", "Foods", ["stew"], []),
  new Emoji("🍥", "fish cake with swirl", "Foods", ["fish_cake"], []),
  new Emoji("🍣", "sushi", "Foods", ["sushi"], []),
  new Emoji("🍱", "bento box", "Foods", ["bento"], []),
  new Emoji("🍛", "curry rice", "Foods", ["curry"], []),
  new Emoji("🍚", "cooked rice", "Foods", ["rice"], []),
  new Emoji("🍙", "rice ball", "Foods", ["rice_ball"], []),
  new Emoji("🍘", "rice cracker", "Foods", ["rice_cracker"], []),
  new Emoji("🍢", "oden", "Foods", ["oden"], []),
  new Emoji("🍡", "dango", "Foods", ["dango"], []),
  new Emoji("🍧", "shaved ice", "Foods", ["shaved_ice"], []),
  new Emoji("🍨", "ice cream", "Foods", ["ice_cream"], []),
  new Emoji("🍦", "soft ice cream", "Foods", ["icecream"], []),
  new Emoji("🍰", "shortcake", "Foods", ["cake"], ["dessert"]),
  new Emoji("🎂", "birthday cake", "Foods", ["birthday"], ["party"]),
  new Emoji("🍮", "custard", "Foods", ["custard"], []),
  new Emoji("🍭", "lollipop", "Foods", ["lollipop"], []),
  new Emoji("🍬", "candy", "Foods", ["candy"], ["sweet"]),
  new Emoji("🍫", "chocolate bar", "Foods", ["chocolate_bar"], []),
  new Emoji("🍿", "popcorn", "Foods", ["popcorn"], []),
  new Emoji("🍩", "doughnut", "Foods", ["doughnut"], []),
  new Emoji("🍪", "cookie", "Foods", ["cookie"], []),
  new Emoji("🥛", "glass of milk", "Foods", ["milk_glass"], []),
  new Emoji("🍼", "baby bottle", "Foods", ["baby_bottle"], ["milk"]),
  new Emoji("☕️", "hot beverage", "Foods", ["coffee"], ["cafe","espresso"]),
  new Emoji("🍵", "teacup without handle", "Foods", ["tea"], ["green","breakfast"]),
  new Emoji("🍶", "sake", "Foods", ["sake"], []),
  new Emoji("🍺", "beer mug", "Foods", ["beer"], ["drink"]),
  new Emoji("🍻", "clinking beer mugs", "Foods", ["beers"], ["drinks"]),
  new Emoji("🥂", "clinking glasses", "Foods", ["clinking_glasses"], ["cheers","toast"]),
  new Emoji("🍷", "wine glass", "Foods", ["wine_glass"], []),
  new Emoji("🥃", "tumbler glass", "Foods", ["tumbler_glass"], ["whisky"]),
  new Emoji("🍸", "cocktail glass", "Foods", ["cocktail"], ["drink"]),
  new Emoji("🍹", "tropical drink", "Foods", ["tropical_drink"], ["summer","vacation"]),
  new Emoji("🍾", "bottle with popping cork", "Foods", ["champagne"], ["bottle","bubbly","celebration"]),
  new Emoji("🥄", "spoon", "Foods", ["spoon"], []),
  new Emoji("🍴", "fork and knife", "Foods", ["fork_and_knife"], ["cutlery"]),
  new Emoji("🍽", "fork and knife with plate", "Foods", ["plate_with_cutlery"], ["dining","dinner"]),
  new Emoji("⚽️", "soccer ball", "Activity", ["soccer"], ["sports"]),
  new Emoji("🏀", "basketball", "Activity", ["basketball"], ["sports"]),
  new Emoji("🏈", "american football", "Activity", ["football"], ["sports"]),
  new Emoji("⚾️", "baseball", "Activity", ["baseball"], ["sports"]),
  new Emoji("🎾", "tennis", "Activity", ["tennis"], ["sports"]),
  new Emoji("🏐", "volleyball", "Activity", ["volleyball"], []),
  new Emoji("🏉", "rugby football", "Activity", ["rugby_football"], []),
  new Emoji("🎱", "pool 8 ball", "Activity", ["8ball"], ["pool","billiards"]),
  new Emoji("🏓", "ping pong", "Activity", ["ping_pong"], []),
  new Emoji("🏸", "badminton", "Activity", ["badminton"], []),
  new Emoji("🥅", "goal net", "Activity", ["goal_net"], []),
  new Emoji("🏒", "ice hockey", "Activity", ["ice_hockey"], []),
  new Emoji("🏑", "field hockey", "Activity", ["field_hockey"], []),
  new Emoji("🏏", "cricket", "Activity", ["cricket"], []),
  new Emoji("⛳️", "flag in hole", "Activity", ["golf"], []),
  new Emoji("🏹", "bow and arrow", "Activity", ["bow_and_arrow"], ["archery"]),
  new Emoji("🎣", "fishing pole", "Activity", ["fishing_pole_and_fish"], []),
  new Emoji("🥊", "boxing glove", "Activity", ["boxing_glove"], []),
  new Emoji("🥋", "martial arts uniform", "Activity", ["martial_arts_uniform"], []),
  new Emoji("⛸", "ice skate", "Activity", ["ice_skate"], ["skating"]),
  new Emoji("🎿", "skis", "Activity", ["ski"], []),
  new Emoji("⛷", "skier", "Activity", ["skier"], []),
  new Emoji("🏂", "snowboarder", "Activity", ["snowboarder"], []),
  new Emoji("🏋️‍♀️", "woman lifting weights", "Activity", ["weight_lifting_woman"], ["gym","workout"]),
  new Emoji("🏋", "person lifting weights", "Activity", ["weight_lifting_man"], ["gym","workout"]),
  new Emoji("🤺", "person fencing", "Activity", ["person_fencing"], []),
  new Emoji("🤼‍♀", "women wrestling", "Activity", ["women_wrestling"], []),
  new Emoji("🤼‍♂", "men wrestling", "Activity", ["men_wrestling"], []),
  new Emoji("🤸‍♀", "woman cartwheeling", "Activity", ["woman_cartwheeling"], []),
  new Emoji("🤸‍♂", "man cartwheeling", "Activity", ["man_cartwheeling"], []),
  new Emoji("⛹️‍♀️", "woman bouncing ball", "Activity", ["basketball_woman"], []),
  new Emoji("⛹", "person bouncing ball", "Activity", ["basketball_man"], []),
  new Emoji("🤾‍♀", "woman playing handball", "Activity", ["woman_playing_handball"], []),
  new Emoji("🤾‍♂", "man playing handball", "Activity", ["man_playing_handball"], []),
  new Emoji("🏌️‍♀️", "woman golfing", "Activity", ["golfing_woman"], []),
  new Emoji("🏌", "person golfing", "Activity", ["golfing_man"], []),
  new Emoji("🏄‍♀", "woman surfing", "Activity", ["surfing_woman"], []),
  new Emoji("🏄", "person surfing", "Activity", ["surfing_man","surfer"], []),
  new Emoji("🏊‍♀", "woman swimming", "Activity", ["swimming_woman"], []),
  new Emoji("🏊", "person swimming", "Activity", ["swimming_man","swimmer"], []),
  new Emoji("🤽‍♀", "woman playing water polo", "Activity", ["woman_playing_water_polo"], []),
  new Emoji("🤽‍♂", "man playing water polo", "Activity", ["man_playing_water_polo"], []),
  new Emoji("🚣‍♀", "woman rowing boat", "Activity", ["rowing_woman"], []),
  new Emoji("🚣", "person rowing boat", "Activity", ["rowing_man","rowboat"], []),
  new Emoji("🏇", "horse racing", "Activity", ["horse_racing"], []),
  new Emoji("🚴‍♀", "woman biking", "Activity", ["biking_woman"], []),
  new Emoji("🚴", "person biking", "Activity", ["biking_man","bicyclist"], []),
  new Emoji("🚵‍♀", "woman mountain biking", "Activity", ["mountain_biking_woman"], []),
  new Emoji("🚵", "person mountain biking", "Activity", ["mountain_biking_man","mountain_bicyclist"], []),
  new Emoji("🎽", "running shirt", "Activity", ["running_shirt_with_sash"], ["marathon"]),
  new Emoji("🏅", "sports medal", "Activity", ["medal_sports"], ["gold","winner"]),
  new Emoji("🎖", "military medal", "Activity", ["medal_military"], []),
  new Emoji("🥇", "1st place medal", "Activity", ["1st_place_medal"], ["gold"]),
  new Emoji("🥈", "2nd place medal", "Activity", ["2nd_place_medal"], ["silver"]),
  new Emoji("🥉", "3rd place medal", "Activity", ["3rd_place_medal"], ["bronze"]),
  new Emoji("🏆", "trophy", "Activity", ["trophy"], ["award","contest","winner"]),
  new Emoji("🏵", "rosette", "Activity", ["rosette"], []),
  new Emoji("🎗", "reminder ribbon", "Activity", ["reminder_ribbon"], []),
  new Emoji("🎫", "ticket", "Activity", ["ticket"], []),
  new Emoji("🎟", "admission tickets", "Activity", ["tickets"], []),
  new Emoji("🎪", "circus tent", "Activity", ["circus_tent"], []),
  new Emoji("🤹‍♀", "woman juggling", "Activity", ["woman_juggling"], []),
  new Emoji("🤹‍♂", "man juggling", "Activity", ["man_juggling"], []),
  new Emoji("🎭", "performing arts", "Activity", ["performing_arts"], ["theater","drama"]),
  new Emoji("🎨", "artist palette", "Activity", ["art"], ["design","paint"]),
  new Emoji("🎬", "clapper board", "Activity", ["clapper"], ["film"]),
  new Emoji("🎤", "microphone", "Activity", ["microphone"], ["sing"]),
  new Emoji("🎧", "headphone", "Activity", ["headphones"], ["music","earphones"]),
  new Emoji("🎼", "musical score", "Activity", ["musical_score"], []),
  new Emoji("🎹", "musical keyboard", "Activity", ["musical_keyboard"], ["piano"]),
  new Emoji("🥁", "drum", "Activity", ["drum"], []),
  new Emoji("🎷", "saxophone", "Activity", ["saxophone"], []),
  new Emoji("🎺", "trumpet", "Activity", ["trumpet"], []),
  new Emoji("🎸", "guitar", "Activity", ["guitar"], ["rock"]),
  new Emoji("🎻", "violin", "Activity", ["violin"], []),
  new Emoji("🎲", "game die", "Activity", ["game_die"], ["dice","gambling"]),
  new Emoji("🎯", "direct hit", "Activity", ["dart"], ["target"]),
  new Emoji("🎳", "bowling", "Activity", ["bowling"], []),
  new Emoji("🎮", "video game", "Activity", ["video_game"], ["play","controller","console"]),
  new Emoji("🎰", "slot machine", "Activity", ["slot_machine"], []),
  new Emoji("🚗", "automobile", "Places", ["car","red_car"], []),
  new Emoji("🚕", "taxi", "Places", ["taxi"], []),
  new Emoji("🚙", "sport utility vehicle", "Places", ["blue_car"], []),
  new Emoji("🚌", "bus", "Places", ["bus"], []),
  new Emoji("🚎", "trolleybus", "Places", ["trolleybus"], []),
  new Emoji("🏎", "racing car", "Places", ["racing_car"], []),
  new Emoji("🚓", "police car", "Places", ["police_car"], []),
  new Emoji("🚑", "ambulance", "Places", ["ambulance"], []),
  new Emoji("🚒", "fire engine", "Places", ["fire_engine"], []),
  new Emoji("🚐", "minibus", "Places", ["minibus"], []),
  new Emoji("🚚", "delivery truck", "Places", ["truck"], []),
  new Emoji("🚛", "articulated lorry", "Places", ["articulated_lorry"], []),
  new Emoji("🚜", "tractor", "Places", ["tractor"], []),
  new Emoji("🛴", "kick scooter", "Places", ["kick_scooter"], []),
  new Emoji("🚲", "bicycle", "Places", ["bike"], ["bicycle"]),
  new Emoji("🛵", "motor scooter", "Places", ["motor_scooter"], []),
  new Emoji("🏍", "motorcycle", "Places", ["motorcycle"], []),
  new Emoji("🚨", "police car light", "Places", ["rotating_light"], ["911","emergency"]),
  new Emoji("🚔", "oncoming police car", "Places", ["oncoming_police_car"], []),
  new Emoji("🚍", "oncoming bus", "Places", ["oncoming_bus"], []),
  new Emoji("🚘", "oncoming automobile", "Places", ["oncoming_automobile"], []),
  new Emoji("🚖", "oncoming taxi", "Places", ["oncoming_taxi"], []),
  new Emoji("🚡", "aerial tramway", "Places", ["aerial_tramway"], []),
  new Emoji("🚠", "mountain cableway", "Places", ["mountain_cableway"], []),
  new Emoji("🚟", "suspension railway", "Places", ["suspension_railway"], []),
  new Emoji("🚃", "railway car", "Places", ["railway_car"], []),
  new Emoji("🚋", "tram car", "Places", ["train"], []),
  new Emoji("🚞", "mountain railway", "Places", ["mountain_railway"], []),
  new Emoji("🚝", "monorail", "Places", ["monorail"], []),
  new Emoji("🚄", "high-speed train", "Places", ["bullettrain_side"], ["train"]),
  new Emoji("🚅", "high-speed train with bullet nose", "Places", ["bullettrain_front"], ["train"]),
  new Emoji("🚈", "light rail", "Places", ["light_rail"], []),
  new Emoji("🚂", "locomotive", "Places", ["steam_locomotive"], ["train"]),
  new Emoji("🚆", "train", "Places", ["train2"], []),
  new Emoji("🚇", "metro", "Places", ["metro"], []),
  new Emoji("🚊", "tram", "Places", ["tram"], []),
  new Emoji("🚉", "station", "Places", ["station"], []),
  new Emoji("🚁", "helicopter", "Places", ["helicopter"], []),
  new Emoji("🛩", "small airplane", "Places", ["small_airplane"], ["flight"]),
  new Emoji("✈️", "airplane", "Places", ["airplane"], ["flight"]),
  new Emoji("🛫", "airplane departure", "Places", ["flight_departure"], []),
  new Emoji("🛬", "airplane arrival", "Places", ["flight_arrival"], []),
  new Emoji("🚀", "rocket", "Places", ["rocket"], ["ship","launch"]),
  new Emoji("🛰", "satellite", "Places", ["artificial_satellite"], ["orbit","space"]),
  new Emoji("💺", "seat", "Places", ["seat"], []),
  new Emoji("🛶", "canoe", "Places", ["canoe"], []),
  new Emoji("⛵️", "sailboat", "Places", ["boat","sailboat"], []),
  new Emoji("🛥", "motor boat", "Places", ["motor_boat"], []),
  new Emoji("🚤", "speedboat", "Places", ["speedboat"], ["ship"]),
  new Emoji("🛳", "passenger ship", "Places", ["passenger_ship"], ["cruise"]),
  new Emoji("⛴", "ferry", "Places", ["ferry"], []),
  new Emoji("🚢", "ship", "Places", ["ship"], []),
  new Emoji("⚓️", "anchor", "Places", ["anchor"], ["ship"]),
  new Emoji("🚧", "construction", "Places", ["construction"], ["wip"]),
  new Emoji("⛽️", "fuel pump", "Places", ["fuelpump"], []),
  new Emoji("🚏", "bus stop", "Places", ["busstop"], []),
  new Emoji("🚦", "vertical traffic light", "Places", ["vertical_traffic_light"], ["semaphore"]),
  new Emoji("🚥", "horizontal traffic light", "Places", ["traffic_light"], []),
  new Emoji("🗺", "world map", "Places", ["world_map"], ["travel"]),
  new Emoji("🗿", "moai", "Places", ["moyai"], ["stone"]),
  new Emoji("🗽", "Statue of Liberty", "Places", ["statue_of_liberty"], []),
  new Emoji("⛲️", "fountain", "Places", ["fountain"], []),
  new Emoji("🗼", "Tokyo tower", "Places", ["tokyo_tower"], []),
  new Emoji("🏰", "castle", "Places", ["european_castle"], []),
  new Emoji("🏯", "Japanese castle", "Places", ["japanese_castle"], []),
  new Emoji("🏟", "stadium", "Places", ["stadium"], []),
  new Emoji("🎡", "ferris wheel", "Places", ["ferris_wheel"], []),
  new Emoji("🎢", "roller coaster", "Places", ["roller_coaster"], []),
  new Emoji("🎠", "carousel horse", "Places", ["carousel_horse"], []),
  new Emoji("⛱", "umbrella on ground", "Places", ["parasol_on_ground"], ["beach_umbrella"]),
  new Emoji("🏖", "beach with umbrella", "Places", ["beach_umbrella"], []),
  new Emoji("🏝", "desert island", "Places", ["desert_island"], []),
  new Emoji("⛰", "mountain", "Places", ["mountain"], []),
  new Emoji("🏔", "snow-capped mountain", "Places", ["mountain_snow"], []),
  new Emoji("🗻", "mount fuji", "Places", ["mount_fuji"], []),
  new Emoji("🌋", "volcano", "Places", ["volcano"], []),
  new Emoji("🏜", "desert", "Places", ["desert"], []),
  new Emoji("🏕", "camping", "Places", ["camping"], []),
  new Emoji("⛺️", "tent", "Places", ["tent"], ["camping"]),
  new Emoji("🛤", "railway track", "Places", ["railway_track"], []),
  new Emoji("🛣", "motorway", "Places", ["motorway"], []),
  new Emoji("🏗", "building construction", "Places", ["building_construction"], []),
  new Emoji("🏭", "factory", "Places", ["factory"], []),
  new Emoji("🏠", "house", "Places", ["house"], []),
  new Emoji("🏡", "house with garden", "Places", ["house_with_garden"], []),
  new Emoji("🏘", "house", "Places", ["houses"], []),
  new Emoji("🏚", "derelict house", "Places", ["derelict_house"], []),
  new Emoji("🏢", "office building", "Places", ["office"], []),
  new Emoji("🏬", "department store", "Places", ["department_store"], []),
  new Emoji("🏣", "Japanese post office", "Places", ["post_office"], []),
  new Emoji("🏤", "post office", "Places", ["european_post_office"], []),
  new Emoji("🏥", "hospital", "Places", ["hospital"], []),
  new Emoji("🏦", "bank", "Places", ["bank"], []),
  new Emoji("🏨", "hotel", "Places", ["hotel"], []),
  new Emoji("🏪", "convenience store", "Places", ["convenience_store"], []),
  new Emoji("🏫", "school", "Places", ["school"], []),
  new Emoji("🏩", "love hotel", "Places", ["love_hotel"], []),
  new Emoji("💒", "wedding", "Places", ["wedding"], ["marriage"]),
  new Emoji("🏛", "classical building", "Places", ["classical_building"], []),
  new Emoji("⛪️", "church", "Places", ["church"], []),
  new Emoji("🕌", "mosque", "Places", ["mosque"], []),
  new Emoji("🕍", "synagogue", "Places", ["synagogue"], []),
  new Emoji("🕋", "kaaba", "Places", ["kaaba"], []),
  new Emoji("⛩", "shinto shrine", "Places", ["shinto_shrine"], []),
  new Emoji("🗾", "map of Japan", "Places", ["japan"], []),
  new Emoji("🎑", "moon viewing ceremony", "Places", ["rice_scene"], []),
  new Emoji("🏞", "national park", "Places", ["national_park"], []),
  new Emoji("🌅", "sunrise", "Places", ["sunrise"], []),
  new Emoji("🌄", "sunrise over mountains", "Places", ["sunrise_over_mountains"], []),
  new Emoji("🌠", "shooting star", "Places", ["stars"], []),
  new Emoji("🎇", "sparkler", "Places", ["sparkler"], []),
  new Emoji("🎆", "fireworks", "Places", ["fireworks"], ["festival","celebration"]),
  new Emoji("🌇", "sunset", "Places", ["city_sunrise"], []),
  new Emoji("🌆", "cityscape at dusk", "Places", ["city_sunset"], []),
  new Emoji("🏙", "cityscape", "Places", ["cityscape"], ["skyline"]),
  new Emoji("🌃", "night with stars", "Places", ["night_with_stars"], []),
  new Emoji("🌌", "milky way", "Places", ["milky_way"], []),
  new Emoji("🌉", "bridge at night", "Places", ["bridge_at_night"], []),
  new Emoji("🌁", "foggy", "Places", ["foggy"], ["karl"]),
  new Emoji("⌚️", "watch", "Objects", ["watch"], ["time"]),
  new Emoji("📱", "mobile phone", "Objects", ["iphone"], ["smartphone","mobile"]),
  new Emoji("📲", "mobile phone with arrow", "Objects", ["calling"], ["call","incoming"]),
  new Emoji("💻", "laptop computer", "Objects", ["computer"], ["desktop","screen"]),
  new Emoji("⌨️", "keyboard", "Objects", ["keyboard"], []),
  new Emoji("🖥", "desktop computer", "Objects", ["desktop_computer"], []),
  new Emoji("🖨", "printer", "Objects", ["printer"], []),
  new Emoji("🖱", "computer mouse", "Objects", ["computer_mouse"], []),
  new Emoji("🖲", "trackball", "Objects", ["trackball"], []),
  new Emoji("🕹", "joystick", "Objects", ["joystick"], []),
  new Emoji("🗜", "clamp", "Objects", ["clamp"], []),
  new Emoji("💽", "computer disk", "Objects", ["minidisc"], []),
  new Emoji("💾", "floppy disk", "Objects", ["floppy_disk"], ["save"]),
  new Emoji("💿", "optical disk", "Objects", ["cd"], []),
  new Emoji("📀", "dvd", "Objects", ["dvd"], []),
  new Emoji("📼", "videocassette", "Objects", ["vhs"], []),
  new Emoji("📷", "camera", "Objects", ["camera"], ["photo"]),
  new Emoji("📸", "camera with flash", "Objects", ["camera_flash"], ["photo"]),
  new Emoji("📹", "video camera", "Objects", ["video_camera"], []),
  new Emoji("🎥", "movie camera", "Objects", ["movie_camera"], ["film","video"]),
  new Emoji("📽", "film projector", "Objects", ["film_projector"], []),
  new Emoji("🎞", "film frames", "Objects", ["film_strip"], []),
  new Emoji("📞", "telephone receiver", "Objects", ["telephone_receiver"], ["phone","call"]),
  new Emoji("☎️", "telephone", "Objects", ["phone","telephone"], []),
  new Emoji("📟", "pager", "Objects", ["pager"], []),
  new Emoji("📠", "fax machine", "Objects", ["fax"], []),
  new Emoji("📺", "television", "Objects", ["tv"], []),
  new Emoji("📻", "radio", "Objects", ["radio"], ["podcast"]),
  new Emoji("🎙", "studio microphone", "Objects", ["studio_microphone"], ["podcast"]),
  new Emoji("🎚", "level slider", "Objects", ["level_slider"], []),
  new Emoji("🎛", "control knobs", "Objects", ["control_knobs"], []),
  new Emoji("⏱", "stopwatch", "Objects", ["stopwatch"], []),
  new Emoji("⏲", "timer clock", "Objects", ["timer_clock"], []),
  new Emoji("⏰", "alarm clock", "Objects", ["alarm_clock"], ["morning"]),
  new Emoji("🕰", "mantelpiece clock", "Objects", ["mantelpiece_clock"], []),
  new Emoji("⌛️", "hourglass", "Objects", ["hourglass"], ["time"]),
  new Emoji("⏳", "hourglass with flowing sand", "Objects", ["hourglass_flowing_sand"], ["time"]),
  new Emoji("📡", "satellite antenna", "Objects", ["satellite"], ["signal"]),
  new Emoji("🔋", "battery", "Objects", ["battery"], ["power"]),
  new Emoji("🔌", "electric plug", "Objects", ["electric_plug"], []),
  new Emoji("💡", "light bulb", "Objects", ["bulb"], ["idea","light"]),
  new Emoji("🔦", "flashlight", "Objects", ["flashlight"], []),
  new Emoji("🕯", "candle", "Objects", ["candle"], []),
  new Emoji("🗑", "wastebasket", "Objects", ["wastebasket"], ["trash"]),
  new Emoji("🛢", "oil drum", "Objects", ["oil_drum"], []),
  new Emoji("💸", "money with wings", "Objects", ["money_with_wings"], ["dollar"]),
  new Emoji("💵", "dollar banknote", "Objects", ["dollar"], ["money"]),
  new Emoji("💴", "yen banknote", "Objects", ["yen"], []),
  new Emoji("💶", "euro banknote", "Objects", ["euro"], []),
  new Emoji("💷", "pound banknote", "Objects", ["pound"], []),
  new Emoji("💰", "money bag", "Objects", ["moneybag"], ["dollar","cream"]),
  new Emoji("💳", "credit card", "Objects", ["credit_card"], ["subscription"]),
  new Emoji("💎", "gem stone", "Objects", ["gem"], ["diamond"]),
  new Emoji("⚖️", "balance scale", "Objects", ["balance_scale"], []),
  new Emoji("🔧", "wrench", "Objects", ["wrench"], ["tool"]),
  new Emoji("🔨", "hammer", "Objects", ["hammer"], ["tool"]),
  new Emoji("⚒", "hammer and pick", "Objects", ["hammer_and_pick"], []),
  new Emoji("🛠", "hammer and wrench", "Objects", ["hammer_and_wrench"], []),
  new Emoji("⛏", "pick", "Objects", ["pick"], []),
  new Emoji("🔩", "nut and bolt", "Objects", ["nut_and_bolt"], []),
  new Emoji("⚙️", "gear", "Objects", ["gear"], []),
  new Emoji("⛓", "chains", "Objects", ["chains"], []),
  new Emoji("🔫", "pistol", "Objects", ["gun"], ["shoot","weapon"]),
  new Emoji("💣", "bomb", "Objects", ["bomb"], ["boom"]),
  new Emoji("🔪", "kitchen knife", "Objects", ["hocho","knife"], ["cut","chop"]),
  new Emoji("🗡", "dagger", "Objects", ["dagger"], []),
  new Emoji("⚔️", "crossed swords", "Objects", ["crossed_swords"], []),
  new Emoji("🛡", "shield", "Objects", ["shield"], []),
  new Emoji("🚬", "cigarette", "Objects", ["smoking"], ["cigarette"]),
  new Emoji("⚰️", "coffin", "Objects", ["coffin"], ["funeral"]),
  new Emoji("⚱️", "funeral urn", "Objects", ["funeral_urn"], []),
  new Emoji("🏺", "amphora", "Objects", ["amphora"], []),
  new Emoji("🔮", "crystal ball", "Objects", ["crystal_ball"], ["fortune"]),
  new Emoji("📿", "prayer beads", "Objects", ["prayer_beads"], []),
  new Emoji("💈", "barber pole", "Objects", ["barber"], []),
  new Emoji("⚗️", "alembic", "Objects", ["alembic"], []),
  new Emoji("🔭", "telescope", "Objects", ["telescope"], []),
  new Emoji("🔬", "microscope", "Objects", ["microscope"], ["science","laboratory","investigate"]),
  new Emoji("🕳", "hole", "Objects", ["hole"], []),
  new Emoji("💊", "pill", "Objects", ["pill"], ["health","medicine"]),
  new Emoji("💉", "syringe", "Objects", ["syringe"], ["health","hospital","needle"]),
  new Emoji("🌡", "thermometer", "Objects", ["thermometer"], []),
  new Emoji("🚽", "toilet", "Objects", ["toilet"], ["wc"]),
  new Emoji("🚰", "potable water", "Objects", ["potable_water"], []),
  new Emoji("🚿", "shower", "Objects", ["shower"], ["bath"]),
  new Emoji("🛁", "bathtub", "Objects", ["bathtub"], []),
  new Emoji("🛀", "person taking bath", "Objects", ["bath"], ["shower"]),
  new Emoji("🛎", "bellhop bell", "Objects", ["bellhop_bell"], []),
  new Emoji("🔑", "key", "Objects", ["key"], ["lock","password"]),
  new Emoji("🗝", "old key", "Objects", ["old_key"], []),
  new Emoji("🚪", "door", "Objects", ["door"], []),
  new Emoji("🛋", "couch and lamp", "Objects", ["couch_and_lamp"], []),
  new Emoji("🛏", "bed", "Objects", ["bed"], []),
  new Emoji("🛌", "person in bed", "Objects", ["sleeping_bed"], []),
  new Emoji("🖼", "framed picture", "Objects", ["framed_picture"], []),
  new Emoji("🛍", "shopping bags", "Objects", ["shopping"], ["bags"]),
  new Emoji("🛒", "shopping cart", "Objects", ["shopping_cart"], []),
  new Emoji("🎁", "wrapped gift", "Objects", ["gift"], ["present","birthday","christmas"]),
  new Emoji("🎈", "balloon", "Objects", ["balloon"], ["party","birthday"]),
  new Emoji("🎏", "carp streamer", "Objects", ["flags"], []),
  new Emoji("🎀", "ribbon", "Objects", ["ribbon"], []),
  new Emoji("🎊", "confetti ball", "Objects", ["confetti_ball"], []),
  new Emoji("🎉", "party popper", "Objects", ["tada"], ["hooray","party"]),
  new Emoji("🎎", "Japanese dolls", "Objects", ["dolls"], []),
  new Emoji("🏮", "red paper lantern", "Objects", ["izakaya_lantern","lantern"], []),
  new Emoji("🎐", "wind chime", "Objects", ["wind_chime"], []),
  new Emoji("✉️", "envelope", "Objects", ["email","envelope"], ["letter"]),
  new Emoji("📩", "envelope with arrow", "Objects", ["envelope_with_arrow"], []),
  new Emoji("📨", "incoming envelope", "Objects", ["incoming_envelope"], []),
  new Emoji("📧", "e-mail", "Objects", ["e-mail"], []),
  new Emoji("💌", "love letter", "Objects", ["love_letter"], ["email","envelope"]),
  new Emoji("📥", "inbox tray", "Objects", ["inbox_tray"], []),
  new Emoji("📤", "outbox tray", "Objects", ["outbox_tray"], []),
  new Emoji("📦", "package", "Objects", ["package"], ["shipping"]),
  new Emoji("🏷", "label", "Objects", ["label"], ["tag"]),
  new Emoji("📪", "closed mailbox with lowered flag", "Objects", ["mailbox_closed"], []),
  new Emoji("📫", "closed mailbox with raised flag", "Objects", ["mailbox"], []),
  new Emoji("📬", "open mailbox with raised flag", "Objects", ["mailbox_with_mail"], []),
  new Emoji("📭", "open mailbox with lowered flag", "Objects", ["mailbox_with_no_mail"], []),
  new Emoji("📮", "postbox", "Objects", ["postbox"], []),
  new Emoji("📯", "postal horn", "Objects", ["postal_horn"], []),
  new Emoji("📜", "scroll", "Objects", ["scroll"], ["document"]),
  new Emoji("📃", "page with curl", "Objects", ["page_with_curl"], []),
  new Emoji("📄", "page facing up", "Objects", ["page_facing_up"], ["document"]),
  new Emoji("📑", "bookmark tabs", "Objects", ["bookmark_tabs"], []),
  new Emoji("📊", "bar chart", "Objects", ["bar_chart"], ["stats","metrics"]),
  new Emoji("📈", "chart increasing", "Objects", ["chart_with_upwards_trend"], ["graph","metrics"]),
  new Emoji("📉", "chart decreasing", "Objects", ["chart_with_downwards_trend"], ["graph","metrics"]),
  new Emoji("🗒", "spiral notepad", "Objects", ["spiral_notepad"], []),
  new Emoji("🗓", "spiral calendar", "Objects", ["spiral_calendar"], []),
  new Emoji("📆", "tear-off calendar", "Objects", ["calendar"], ["schedule"]),
  new Emoji("📅", "calendar", "Objects", ["date"], ["calendar","schedule"]),
  new Emoji("📇", "card index", "Objects", ["card_index"], []),
  new Emoji("🗃", "card file box", "Objects", ["card_file_box"], []),
  new Emoji("🗳", "ballot box with ballot", "Objects", ["ballot_box"], []),
  new Emoji("🗄", "file cabinet", "Objects", ["file_cabinet"], []),
  new Emoji("📋", "clipboard", "Objects", ["clipboard"], []),
  new Emoji("📁", "file folder", "Objects", ["file_folder"], ["directory"]),
  new Emoji("📂", "open file folder", "Objects", ["open_file_folder"], []),
  new Emoji("🗂", "card index dividers", "Objects", ["card_index_dividers"], []),
  new Emoji("🗞", "rolled-up newspaper", "Objects", ["newspaper_roll"], ["press"]),
  new Emoji("📰", "newspaper", "Objects", ["newspaper"], ["press"]),
  new Emoji("📓", "notebook", "Objects", ["notebook"], []),
  new Emoji("📔", "notebook with decorative cover", "Objects", ["notebook_with_decorative_cover"], []),
  new Emoji("📒", "ledger", "Objects", ["ledger"], []),
  new Emoji("📕", "closed book", "Objects", ["closed_book"], []),
  new Emoji("📗", "green book", "Objects", ["green_book"], []),
  new Emoji("📘", "blue book", "Objects", ["blue_book"], []),
  new Emoji("📙", "orange book", "Objects", ["orange_book"], []),
  new Emoji("📚", "books", "Objects", ["books"], ["library"]),
  new Emoji("📖", "open book", "Objects", ["book","open_book"], []),
  new Emoji("🔖", "bookmark", "Objects", ["bookmark"], []),
  new Emoji("🔗", "link", "Objects", ["link"], []),
  new Emoji("📎", "paperclip", "Objects", ["paperclip"], []),
  new Emoji("🖇", "linked paperclips", "Objects", ["paperclips"], []),
  new Emoji("📐", "triangular ruler", "Objects", ["triangular_ruler"], []),
  new Emoji("📏", "straight ruler", "Objects", ["straight_ruler"], []),
  new Emoji("📌", "pushpin", "Objects", ["pushpin"], ["location"]),
  new Emoji("📍", "round pushpin", "Objects", ["round_pushpin"], ["location"]),
  new Emoji("✂️", "scissors", "Objects", ["scissors"], ["cut"]),
  new Emoji("🖊", "pen", "Objects", ["pen"], []),
  new Emoji("🖋", "fountain pen", "Objects", ["fountain_pen"], []),
  new Emoji("✒️", "black nib", "Objects", ["black_nib"], []),
  new Emoji("🖌", "paintbrush", "Objects", ["paintbrush"], []),
  new Emoji("🖍", "crayon", "Objects", ["crayon"], []),
  new Emoji("📝", "memo", "Objects", ["memo","pencil"], ["document","note"]),
  new Emoji("✏️", "pencil", "Objects", ["pencil2"], []),
  new Emoji("🔍", "left-pointing magnifying glass", "Objects", ["mag"], ["search","zoom"]),
  new Emoji("🔎", "right-pointing magnifying glass", "Objects", ["mag_right"], []),
  new Emoji("🔏", "locked with pen", "Objects", ["lock_with_ink_pen"], []),
  new Emoji("🔐", "locked with key", "Objects", ["closed_lock_with_key"], ["security"]),
  new Emoji("🔒", "locked", "Objects", ["lock"], ["security","private"]),
  new Emoji("🔓", "unlocked", "Objects", ["unlock"], ["security"]),
  new Emoji("❤️", "red heart", "Symbols", ["heart"], ["love"]),
  new Emoji("💛", "yellow heart", "Symbols", ["yellow_heart"], []),
  new Emoji("💚", "green heart", "Symbols", ["green_heart"], []),
  new Emoji("💙", "blue heart", "Symbols", ["blue_heart"], []),
  new Emoji("💜", "purple heart", "Symbols", ["purple_heart"], []),
  new Emoji("🖤", "black heart", "Symbols", ["black_heart"], []),
  new Emoji("💔", "broken heart", "Symbols", ["broken_heart"], []),
  new Emoji("❣️", "heavy heart exclamation", "Symbols", ["heavy_heart_exclamation"], []),
  new Emoji("💕", "two hearts", "Symbols", ["two_hearts"], []),
  new Emoji("💞", "revolving hearts", "Symbols", ["revolving_hearts"], []),
  new Emoji("💓", "beating heart", "Symbols", ["heartbeat"], []),
  new Emoji("💗", "growing heart", "Symbols", ["heartpulse"], []),
  new Emoji("💖", "sparkling heart", "Symbols", ["sparkling_heart"], []),
  new Emoji("💘", "heart with arrow", "Symbols", ["cupid"], ["love","heart"]),
  new Emoji("💝", "heart with ribbon", "Symbols", ["gift_heart"], ["chocolates"]),
  new Emoji("💟", "heart decoration", "Symbols", ["heart_decoration"], []),
  new Emoji("☮️", "peace symbol", "Symbols", ["peace_symbol"], []),
  new Emoji("✝️", "latin cross", "Symbols", ["latin_cross"], []),
  new Emoji("☪️", "star and crescent", "Symbols", ["star_and_crescent"], []),
  new Emoji("🕉", "om", "Symbols", ["om"], []),
  new Emoji("☸️", "wheel of dharma", "Symbols", ["wheel_of_dharma"], []),
  new Emoji("✡️", "star of David", "Symbols", ["star_of_david"], []),
  new Emoji("🔯", "dotted six-pointed star", "Symbols", ["six_pointed_star"], []),
  new Emoji("🕎", "menorah", "Symbols", ["menorah"], []),
  new Emoji("☯️", "yin yang", "Symbols", ["yin_yang"], []),
  new Emoji("☦️", "orthodox cross", "Symbols", ["orthodox_cross"], []),
  new Emoji("🛐", "place of worship", "Symbols", ["place_of_worship"], []),
  new Emoji("⛎", "Ophiuchus", "Symbols", ["ophiuchus"], []),
  new Emoji("♈️", "Aries", "Symbols", ["aries"], []),
  new Emoji("♉️", "Taurus", "Symbols", ["taurus"], []),
  new Emoji("♊️", "Gemini", "Symbols", ["gemini"], []),
  new Emoji("♋️", "Cancer", "Symbols", ["cancer"], []),
  new Emoji("♌️", "Leo", "Symbols", ["leo"], []),
  new Emoji("♍️", "Virgo", "Symbols", ["virgo"], []),
  new Emoji("♎️", "Libra", "Symbols", ["libra"], []),
  new Emoji("♏️", "Scorpius", "Symbols", ["scorpius"], []),
  new Emoji("♐️", "Sagittarius", "Symbols", ["sagittarius"], []),
  new Emoji("♑️", "Capricorn", "Symbols", ["capricorn"], []),
  new Emoji("♒️", "Aquarius", "Symbols", ["aquarius"], []),
  new Emoji("♓️", "Pisces", "Symbols", ["pisces"], []),
  new Emoji("🆔", "ID button", "Symbols", ["id"], []),
  new Emoji("⚛️", "atom symbol", "Symbols", ["atom_symbol"], []),
  new Emoji("🉑", "Japanese “acceptable” button", "Symbols", ["accept"], []),
  new Emoji("☢️", "radioactive", "Symbols", ["radioactive"], []),
  new Emoji("☣️", "biohazard", "Symbols", ["biohazard"], []),
  new Emoji("📴", "mobile phone off", "Symbols", ["mobile_phone_off"], ["mute","off"]),
  new Emoji("📳", "vibration mode", "Symbols", ["vibration_mode"], []),
  new Emoji("🈶", "Japanese “not free of charge” button", "Symbols", ["u6709"], []),
  new Emoji("🈚️", "Japanese “free of charge” button", "Symbols", ["u7121"], []),
  new Emoji("🈸", "Japanese “application” button", "Symbols", ["u7533"], []),
  new Emoji("🈺", "Japanese “open for business” button", "Symbols", ["u55b6"], []),
  new Emoji("🈷️", "Japanese “monthly amount” button", "Symbols", ["u6708"], []),
  new Emoji("✴️", "eight-pointed star", "Symbols", ["eight_pointed_black_star"], []),
  new Emoji("🆚", "VS button", "Symbols", ["vs"], []),
  new Emoji("💮", "white flower", "Symbols", ["white_flower"], []),
  new Emoji("🉐", "Japanese “bargain” button", "Symbols", ["ideograph_advantage"], []),
  new Emoji("㊙️", "Japanese “secret” button", "Symbols", ["secret"], []),
  new Emoji("㊗️", "Japanese “congratulations” button", "Symbols", ["congratulations"], []),
  new Emoji("🈴", "Japanese “passing grade” button", "Symbols", ["u5408"], []),
  new Emoji("🈵", "Japanese “no vacancy” button", "Symbols", ["u6e80"], []),
  new Emoji("🈹", "Japanese “discount” button", "Symbols", ["u5272"], []),
  new Emoji("🈲", "Japanese “prohibited” button", "Symbols", ["u7981"], []),
  new Emoji("🅰️", "A button (blood type)", "Symbols", ["a"], []),
  new Emoji("🅱️", "B button (blood type)", "Symbols", ["b"], []),
  new Emoji("🆎", "AB button (blood type)", "Symbols", ["ab"], []),
  new Emoji("🆑", "CL button", "Symbols", ["cl"], []),
  new Emoji("🅾️", "O button (blood type)", "Symbols", ["o2"], []),
  new Emoji("🆘", "SOS button", "Symbols", ["sos"], ["help","emergency"]),
  new Emoji("❌", "cross mark", "Symbols", ["x"], []),
  new Emoji("⭕️", "heavy large circle", "Symbols", ["o"], []),
  new Emoji("🛑", "stop sign", "Symbols", ["stop_sign"], []),
  new Emoji("⛔️", "no entry", "Symbols", ["no_entry"], ["limit"]),
  new Emoji("📛", "name badge", "Symbols", ["name_badge"], []),
  new Emoji("🚫", "prohibited", "Symbols", ["no_entry_sign"], ["block","forbidden"]),
  new Emoji("💯", "hundred points", "Symbols", ["100"], ["score","perfect"]),
  new Emoji("💢", "anger symbol", "Symbols", ["anger"], ["angry"]),
  new Emoji("♨️", "hot springs", "Symbols", ["hotsprings"], []),
  new Emoji("🚷", "no pedestrians", "Symbols", ["no_pedestrians"], []),
  new Emoji("🚯", "no littering", "Symbols", ["do_not_litter"], []),
  new Emoji("🚳", "no bicycles", "Symbols", ["no_bicycles"], []),
  new Emoji("🚱", "non-potable water", "Symbols", ["non-potable_water"], []),
  new Emoji("🔞", "no one under eighteen", "Symbols", ["underage"], []),
  new Emoji("📵", "no mobile phones", "Symbols", ["no_mobile_phones"], []),
  new Emoji("🚭", "no smoking", "Symbols", ["no_smoking"], []),
  new Emoji("❗️", "exclamation mark", "Symbols", ["exclamation","heavy_exclamation_mark"], ["bang"]),
  new Emoji("❕", "white exclamation mark", "Symbols", ["grey_exclamation"], []),
  new Emoji("❓", "question mark", "Symbols", ["question"], ["confused"]),
  new Emoji("❔", "white question mark", "Symbols", ["grey_question"], []),
  new Emoji("‼️", "double exclamation mark", "Symbols", ["bangbang"], []),
  new Emoji("⁉️", "exclamation question mark", "Symbols", ["interrobang"], []),
  new Emoji("🔅", "dim button", "Symbols", ["low_brightness"], []),
  new Emoji("🔆", "bright button", "Symbols", ["high_brightness"], []),
  new Emoji("〽️", "part alternation mark", "Symbols", ["part_alternation_mark"], []),
  new Emoji("⚠️", "warning", "Symbols", ["warning"], ["wip"]),
  new Emoji("🚸", "children crossing", "Symbols", ["children_crossing"], []),
  new Emoji("🔱", "trident emblem", "Symbols", ["trident"], []),
  new Emoji("⚜️", "fleur-de-lis", "Symbols", ["fleur_de_lis"], []),
  new Emoji("🔰", "Japanese symbol for beginner", "Symbols", ["beginner"], []),
  new Emoji("♻️", "recycling symbol", "Symbols", ["recycle"], ["environment","green"]),
  new Emoji("✅", "white heavy check mark", "Symbols", ["white_check_mark"], []),
  new Emoji("🈯️", "Japanese “reserved” button", "Symbols", ["u6307"], []),
  new Emoji("💹", "chart increasing with yen", "Symbols", ["chart"], []),
  new Emoji("❇️", "sparkle", "Symbols", ["sparkle"], []),
  new Emoji("✳️", "eight-spoked asterisk", "Symbols", ["eight_spoked_asterisk"], []),
  new Emoji("❎", "cross mark button", "Symbols", ["negative_squared_cross_mark"], []),
  new Emoji("🌐", "globe with meridians", "Symbols", ["globe_with_meridians"], ["world","global","international"]),
  new Emoji("💠", "diamond with a dot", "Symbols", ["diamond_shape_with_a_dot_inside"], []),
  new Emoji("Ⓜ️", "circled M", "Symbols", ["m"], []),
  new Emoji("🌀", "cyclone", "Symbols", ["cyclone"], ["swirl"]),
  new Emoji("💤", "zzz", "Symbols", ["zzz"], ["sleeping"]),
  new Emoji("🏧", "ATM sign", "Symbols", ["atm"], []),
  new Emoji("🚾", "water closet", "Symbols", ["wc"], ["toilet","restroom"]),
  new Emoji("♿️", "wheelchair symbol", "Symbols", ["wheelchair"], ["accessibility"]),
  new Emoji("🅿️", "P button", "Symbols", ["parking"], []),
  new Emoji("🈳", "Japanese “vacancy” button", "Symbols", ["u7a7a"], []),
  new Emoji("🈂️", "Japanese “service charge” button", "Symbols", ["sa"], []),
  new Emoji("🛂", "passport control", "Symbols", ["passport_control"], []),
  new Emoji("🛃", "customs", "Symbols", ["customs"], []),
  new Emoji("🛄", "baggage claim", "Symbols", ["baggage_claim"], ["airport"]),
  new Emoji("🛅", "left luggage", "Symbols", ["left_luggage"], []),
  new Emoji("🚹", "men’s room", "Symbols", ["mens"], []),
  new Emoji("🚺", "women’s room", "Symbols", ["womens"], []),
  new Emoji("🚼", "baby symbol", "Symbols", ["baby_symbol"], []),
  new Emoji("🚻", "restroom", "Symbols", ["restroom"], ["toilet"]),
  new Emoji("🚮", "litter in bin sign", "Symbols", ["put_litter_in_its_place"], []),
  new Emoji("🎦", "cinema", "Symbols", ["cinema"], ["film","movie"]),
  new Emoji("📶", "antenna bars", "Symbols", ["signal_strength"], ["wifi"]),
  new Emoji("🈁", "Japanese “here” button", "Symbols", ["koko"], []),
  new Emoji("🔣", "input symbols", "Symbols", ["symbols"], []),
  new Emoji("ℹ️", "information", "Symbols", ["information_source"], []),
  new Emoji("🔤", "input latin letters", "Symbols", ["abc"], ["alphabet"]),
  new Emoji("🔡", "input latin lowercase", "Symbols", ["abcd"], []),
  new Emoji("🔠", "input latin uppercase", "Symbols", ["capital_abcd"], ["letters"]),
  new Emoji("🆖", "NG button", "Symbols", ["ng"], []),
  new Emoji("🆗", "OK button", "Symbols", ["ok"], ["yes"]),
  new Emoji("🆙", "UP! button", "Symbols", ["up"], []),
  new Emoji("🆒", "COOL button", "Symbols", ["cool"], []),
  new Emoji("🆕", "NEW button", "Symbols", ["new"], ["fresh"]),
  new Emoji("🆓", "FREE button", "Symbols", ["free"], []),
  new Emoji("0️⃣", "keycap: 0", "Symbols", ["zero"], []),
  new Emoji("1️⃣", "keycap: 1", "Symbols", ["one"], []),
  new Emoji("2️⃣", "keycap: 2", "Symbols", ["two"], []),
  new Emoji("3️⃣", "keycap: 3", "Symbols", ["three"], []),
  new Emoji("4️⃣", "keycap: 4", "Symbols", ["four"], []),
  new Emoji("5️⃣", "keycap: 5", "Symbols", ["five"], []),
  new Emoji("6️⃣", "keycap: 6", "Symbols", ["six"], []),
  new Emoji("7️⃣", "keycap: 7", "Symbols", ["seven"], []),
  new Emoji("8️⃣", "keycap: 8", "Symbols", ["eight"], []),
  new Emoji("9️⃣", "keycap: 9", "Symbols", ["nine"], []),
  new Emoji("🔟", "keycap 10", "Symbols", ["keycap_ten"], []),
  new Emoji("🔢", "input numbers", "Symbols", ["1234"], ["numbers"]),
  new Emoji("#️⃣", "keycap: #", "Symbols", ["hash"], ["number"]),
  new Emoji("*️⃣", "keycap: *", "Symbols", ["asterisk"], []),
  new Emoji("▶️", "play button", "Symbols", ["arrow_forward"], []),
  new Emoji("⏸", "pause button", "Symbols", ["pause_button"], []),
  new Emoji("⏯", "play or pause button", "Symbols", ["play_or_pause_button"], []),
  new Emoji("⏹", "stop button", "Symbols", ["stop_button"], []),
  new Emoji("⏺", "record button", "Symbols", ["record_button"], []),
  new Emoji("⏭", "next track button", "Symbols", ["next_track_button"], []),
  new Emoji("⏮", "last track button", "Symbols", ["previous_track_button"], []),
  new Emoji("⏩", "fast-forward button", "Symbols", ["fast_forward"], []),
  new Emoji("⏪", "fast reverse button", "Symbols", ["rewind"], []),
  new Emoji("⏫", "fast up button", "Symbols", ["arrow_double_up"], []),
  new Emoji("⏬", "fast down button", "Symbols", ["arrow_double_down"], []),
  new Emoji("◀️", "reverse button", "Symbols", ["arrow_backward"], []),
  new Emoji("🔼", "up button", "Symbols", ["arrow_up_small"], []),
  new Emoji("🔽", "down button", "Symbols", ["arrow_down_small"], []),
  new Emoji("➡️", "right arrow", "Symbols", ["arrow_right"], []),
  new Emoji("⬅️", "left arrow", "Symbols", ["arrow_left"], []),
  new Emoji("⬆️", "up arrow", "Symbols", ["arrow_up"], []),
  new Emoji("⬇️", "down arrow", "Symbols", ["arrow_down"], []),
  new Emoji("↗️", "up-right arrow", "Symbols", ["arrow_upper_right"], []),
  new Emoji("↘️", "down-right arrow", "Symbols", ["arrow_lower_right"], []),
  new Emoji("↙️", "down-left arrow", "Symbols", ["arrow_lower_left"], []),
  new Emoji("↖️", "up-left arrow", "Symbols", ["arrow_upper_left"], []),
  new Emoji("↕️", "up-down arrow", "Symbols", ["arrow_up_down"], []),
  new Emoji("↔️", "left-right arrow", "Symbols", ["left_right_arrow"], []),
  new Emoji("↪️", "left arrow curving right", "Symbols", ["arrow_right_hook"], []),
  new Emoji("↩️", "right arrow curving left", "Symbols", ["leftwards_arrow_with_hook"], ["return"]),
  new Emoji("⤴️", "right arrow curving up", "Symbols", ["arrow_heading_up"], []),
  new Emoji("⤵️", "right arrow curving down", "Symbols", ["arrow_heading_down"], []),
  new Emoji("🔀", "shuffle tracks button", "Symbols", ["twisted_rightwards_arrows"], ["shuffle"]),
  new Emoji("🔁", "repeat button", "Symbols", ["repeat"], ["loop"]),
  new Emoji("🔂", "repeat single button", "Symbols", ["repeat_one"], []),
  new Emoji("🔄", "anticlockwise arrows button", "Symbols", ["arrows_counterclockwise"], ["sync"]),
  new Emoji("🔃", "clockwise vertical arrows", "Symbols", ["arrows_clockwise"], []),
  new Emoji("🎵", "musical note", "Symbols", ["musical_note"], []),
  new Emoji("🎶", "musical notes", "Symbols", ["notes"], ["music"]),
  new Emoji("➕", "heavy plus sign", "Symbols", ["heavy_plus_sign"], []),
  new Emoji("➖", "heavy minus sign", "Symbols", ["heavy_minus_sign"], []),
  new Emoji("➗", "heavy division sign", "Symbols", ["heavy_division_sign"], []),
  new Emoji("✖️", "heavy multiplication x", "Symbols", ["heavy_multiplication_x"], []),
  new Emoji("💲", "heavy dollar sign", "Symbols", ["heavy_dollar_sign"], []),
  new Emoji("💱", "currency exchange", "Symbols", ["currency_exchange"], []),
  new Emoji("™️", "trade mark", "Symbols", ["tm"], ["trademark"]),
  new Emoji("©️", "copyright", "Symbols", ["copyright"], []),
  new Emoji("®️", "registered", "Symbols", ["registered"], []),
  new Emoji("〰️", "wavy dash", "Symbols", ["wavy_dash"], []),
  new Emoji("➰", "curly loop", "Symbols", ["curly_loop"], []),
  new Emoji("➿", "double curly loop", "Symbols", ["loop"], []),
  new Emoji("🔚", "END arrow", "Symbols", ["end"], []),
  new Emoji("🔙", "BACK arrow", "Symbols", ["back"], []),
  new Emoji("🔛", "ON! arrow", "Symbols", ["on"], []),
  new Emoji("🔝", "TOP arrow", "Symbols", ["top"], []),
  new Emoji("🔜", "SOON arrow", "Symbols", ["soon"], []),
  new Emoji("✔️", "heavy check mark", "Symbols", ["heavy_check_mark"], []),
  new Emoji("☑️", "ballot box with check", "Symbols", ["ballot_box_with_check"], []),
  new Emoji("🔘", "radio button", "Symbols", ["radio_button"], []),
  new Emoji("⚪️", "white circle", "Symbols", ["white_circle"], []),
  new Emoji("⚫️", "black circle", "Symbols", ["black_circle"], []),
  new Emoji("🔴", "red circle", "Symbols", ["red_circle"], []),
  new Emoji("🔵", "blue circle", "Symbols", ["large_blue_circle"], []),
  new Emoji("🔺", "red triangle pointed up", "Symbols", ["small_red_triangle"], []),
  new Emoji("🔻", "red triangle pointed down", "Symbols", ["small_red_triangle_down"], []),
  new Emoji("🔸", "small orange diamond", "Symbols", ["small_orange_diamond"], []),
  new Emoji("🔹", "small blue diamond", "Symbols", ["small_blue_diamond"], []),
  new Emoji("🔶", "large orange diamond", "Symbols", ["large_orange_diamond"], []),
  new Emoji("🔷", "large blue diamond", "Symbols", ["large_blue_diamond"], []),
  new Emoji("🔳", "white square button", "Symbols", ["white_square_button"], []),
  new Emoji("🔲", "black square button", "Symbols", ["black_square_button"], []),
  new Emoji("▪️", "black small square", "Symbols", ["black_small_square"], []),
  new Emoji("▫️", "white small square", "Symbols", ["white_small_square"], []),
  new Emoji("◾️", "black medium-small square", "Symbols", ["black_medium_small_square"], []),
  new Emoji("◽️", "white medium-small square", "Symbols", ["white_medium_small_square"], []),
  new Emoji("◼️", "black medium square", "Symbols", ["black_medium_square"], []),
  new Emoji("◻️", "white medium square", "Symbols", ["white_medium_square"], []),
  new Emoji("⬛️", "black large square", "Symbols", ["black_large_square"], []),
  new Emoji("⬜️", "white large square", "Symbols", ["white_large_square"], []),
  new Emoji("🔈", "speaker low volume", "Symbols", ["speaker"], []),
  new Emoji("🔇", "muted speaker", "Symbols", ["mute"], ["sound","volume"]),
  new Emoji("🔉", "speaker medium volume", "Symbols", ["sound"], ["volume"]),
  new Emoji("🔊", "speaker high volume", "Symbols", ["loud_sound"], ["volume"]),
  new Emoji("🔔", "bell", "Symbols", ["bell"], ["sound","notification"]),
  new Emoji("🔕", "bell with slash", "Symbols", ["no_bell"], ["volume","off"]),
  new Emoji("📣", "megaphone", "Symbols", ["mega"], []),
  new Emoji("📢", "loudspeaker", "Symbols", ["loudspeaker"], ["announcement"]),
  new Emoji("👁‍🗨", "eye in speech bubble", "Symbols", ["eye_speech_bubble"], []),
  new Emoji("💬", "speech balloon", "Symbols", ["speech_balloon"], ["comment"]),
  new Emoji("💭", "thought balloon", "Symbols", ["thought_balloon"], ["thinking"]),
  new Emoji("🗯", "right anger bubble", "Symbols", ["right_anger_bubble"], []),
  new Emoji("♠️", "spade suit", "Symbols", ["spades"], []),
  new Emoji("♣️", "club suit", "Symbols", ["clubs"], []),
  new Emoji("♥️", "heart suit", "Symbols", ["hearts"], []),
  new Emoji("♦️", "diamond suit", "Symbols", ["diamonds"], []),
  new Emoji("🃏", "joker", "Symbols", ["black_joker"], []),
  new Emoji("🎴", "flower playing cards", "Symbols", ["flower_playing_cards"], []),
  new Emoji("🀄️", "mahjong red dragon", "Symbols", ["mahjong"], []),
  new Emoji("🕐", "one o’clock", "Symbols", ["clock1"], []),
  new Emoji("🕑", "two o’clock", "Symbols", ["clock2"], []),
  new Emoji("🕒", "three o’clock", "Symbols", ["clock3"], []),
  new Emoji("🕓", "four o’clock", "Symbols", ["clock4"], []),
  new Emoji("🕔", "five o’clock", "Symbols", ["clock5"], []),
  new Emoji("🕕", "six o’clock", "Symbols", ["clock6"], []),
  new Emoji("🕖", "seven o’clock", "Symbols", ["clock7"], []),
  new Emoji("🕗", "eight o’clock", "Symbols", ["clock8"], []),
  new Emoji("🕘", "nine o’clock", "Symbols", ["clock9"], []),
  new Emoji("🕙", "ten o’clock", "Symbols", ["clock10"], []),
  new Emoji("🕚", "eleven o’clock", "Symbols", ["clock11"], []),
  new Emoji("🕛", "twelve o’clock", "Symbols", ["clock12"], []),
  new Emoji("🕜", "one-thirty", "Symbols", ["clock130"], []),
  new Emoji("🕝", "two-thirty", "Symbols", ["clock230"], []),
  new Emoji("🕞", "three-thirty", "Symbols", ["clock330"], []),
  new Emoji("🕟", "four-thirty", "Symbols", ["clock430"], []),
  new Emoji("🕠", "five-thirty", "Symbols", ["clock530"], []),
  new Emoji("🕡", "six-thirty", "Symbols", ["clock630"], []),
  new Emoji("🕢", "seven-thirty", "Symbols", ["clock730"], []),
  new Emoji("🕣", "eight-thirty", "Symbols", ["clock830"], []),
  new Emoji("🕤", "nine-thirty", "Symbols", ["clock930"], []),
  new Emoji("🕥", "ten-thirty", "Symbols", ["clock1030"], []),
  new Emoji("🕦", "eleven-thirty", "Symbols", ["clock1130"], []),
  new Emoji("🕧", "twelve-thirty", "Symbols", ["clock1230"], []),
  new Emoji("🏳️", "white flag", "Flags", ["white_flag"], []),
  new Emoji("🏴", "black flag", "Flags", ["black_flag"], []),
  new Emoji("🏁", "chequered flag", "Flags", ["checkered_flag"], ["milestone","finish"]),
  new Emoji("🚩", "triangular flag", "Flags", ["triangular_flag_on_post"], []),
  new Emoji("🏳️‍🌈", "rainbow flag", "Flags", ["rainbow_flag"], ["pride"]),
  new Emoji("🇦🇫", "Afghanistan", "Flags", ["afghanistan"], []),
  new Emoji("🇦🇽", "Åland Islands", "Flags", ["aland_islands"], []),
  new Emoji("🇦🇱", "Albania", "Flags", ["albania"], []),
  new Emoji("🇩🇿", "Algeria", "Flags", ["algeria"], []),
  new Emoji("🇦🇸", "American Samoa", "Flags", ["american_samoa"], []),
  new Emoji("🇦🇩", "Andorra", "Flags", ["andorra"], []),
  new Emoji("🇦🇴", "Angola", "Flags", ["angola"], []),
  new Emoji("🇦🇮", "Anguilla", "Flags", ["anguilla"], []),
  new Emoji("🇦🇶", "Antarctica", "Flags", ["antarctica"], []),
  new Emoji("🇦🇬", "Antigua & Barbuda", "Flags", ["antigua_barbuda"], []),
  new Emoji("🇦🇷", "Argentina", "Flags", ["argentina"], []),
  new Emoji("🇦🇲", "Armenia", "Flags", ["armenia"], []),
  new Emoji("🇦🇼", "Aruba", "Flags", ["aruba"], []),
  new Emoji("🇦🇺", "Australia", "Flags", ["australia"], []),
  new Emoji("🇦🇹", "Austria", "Flags", ["austria"], []),
  new Emoji("🇦🇿", "Azerbaijan", "Flags", ["azerbaijan"], []),
  new Emoji("🇧🇸", "Bahamas", "Flags", ["bahamas"], []),
  new Emoji("🇧🇭", "Bahrain", "Flags", ["bahrain"], []),
  new Emoji("🇧🇩", "Bangladesh", "Flags", ["bangladesh"], []),
  new Emoji("🇧🇧", "Barbados", "Flags", ["barbados"], []),
  new Emoji("🇧🇾", "Belarus", "Flags", ["belarus"], []),
  new Emoji("🇧🇪", "Belgium", "Flags", ["belgium"], []),
  new Emoji("🇧🇿", "Belize", "Flags", ["belize"], []),
  new Emoji("🇧🇯", "Benin", "Flags", ["benin"], []),
  new Emoji("🇧🇲", "Bermuda", "Flags", ["bermuda"], []),
  new Emoji("🇧🇹", "Bhutan", "Flags", ["bhutan"], []),
  new Emoji("🇧🇴", "Bolivia", "Flags", ["bolivia"], []),
  new Emoji("🇧🇶", "Caribbean Netherlands", "Flags", ["caribbean_netherlands"], []),
  new Emoji("🇧🇦", "Bosnia & Herzegovina", "Flags", ["bosnia_herzegovina"], []),
  new Emoji("🇧🇼", "Botswana", "Flags", ["botswana"], []),
  new Emoji("🇧🇷", "Brazil", "Flags", ["brazil"], []),
  new Emoji("🇮🇴", "British Indian Ocean Territory", "Flags", ["british_indian_ocean_territory"], []),
  new Emoji("🇻🇬", "British Virgin Islands", "Flags", ["british_virgin_islands"], []),
  new Emoji("🇧🇳", "Brunei", "Flags", ["brunei"], []),
  new Emoji("🇧🇬", "Bulgaria", "Flags", ["bulgaria"], []),
  new Emoji("🇧🇫", "Burkina Faso", "Flags", ["burkina_faso"], []),
  new Emoji("🇧🇮", "Burundi", "Flags", ["burundi"], []),
  new Emoji("🇨🇻", "Cape Verde", "Flags", ["cape_verde"], []),
  new Emoji("🇰🇭", "Cambodia", "Flags", ["cambodia"], []),
  new Emoji("🇨🇲", "Cameroon", "Flags", ["cameroon"], []),
  new Emoji("🇨🇦", "Canada", "Flags", ["canada"], []),
  new Emoji("🇮🇨", "Canary Islands", "Flags", ["canary_islands"], []),
  new Emoji("🇰🇾", "Cayman Islands", "Flags", ["cayman_islands"], []),
  new Emoji("🇨🇫", "Central African Republic", "Flags", ["central_african_republic"], []),
  new Emoji("🇹🇩", "Chad", "Flags", ["chad"], []),
  new Emoji("🇨🇱", "Chile", "Flags", ["chile"], []),
  new Emoji("🇨🇳", "China", "Flags", ["cn"], ["china"]),
  new Emoji("🇨🇽", "Christmas Island", "Flags", ["christmas_island"], []),
  new Emoji("🇨🇨", "Cocos (Keeling) Islands", "Flags", ["cocos_islands"], ["keeling"]),
  new Emoji("🇨🇴", "Colombia", "Flags", ["colombia"], []),
  new Emoji("🇰🇲", "Comoros", "Flags", ["comoros"], []),
  new Emoji("🇨🇬", "Congo - Brazzaville", "Flags", ["congo_brazzaville"], []),
  new Emoji("🇨🇩", "Congo - Kinshasa", "Flags", ["congo_kinshasa"], []),
  new Emoji("🇨🇰", "Cook Islands", "Flags", ["cook_islands"], []),
  new Emoji("🇨🇷", "Costa Rica", "Flags", ["costa_rica"], []),
  new Emoji("🇨🇮", "Côte d’Ivoire", "Flags", ["cote_divoire"], ["ivory"]),
  new Emoji("🇭🇷", "Croatia", "Flags", ["croatia"], []),
  new Emoji("🇨🇺", "Cuba", "Flags", ["cuba"], []),
  new Emoji("🇨🇼", "Curaçao", "Flags", ["curacao"], []),
  new Emoji("🇨🇾", "Cyprus", "Flags", ["cyprus"], []),
  new Emoji("🇨🇿", "Czech Republic", "Flags", ["czech_republic"], []),
  new Emoji("🇩🇰", "Denmark", "Flags", ["denmark"], []),
  new Emoji("🇩🇯", "Djibouti", "Flags", ["djibouti"], []),
  new Emoji("🇩🇲", "Dominica", "Flags", ["dominica"], []),
  new Emoji("🇩🇴", "Dominican Republic", "Flags", ["dominican_republic"], []),
  new Emoji("🇪🇨", "Ecuador", "Flags", ["ecuador"], []),
  new Emoji("🇪🇬", "Egypt", "Flags", ["egypt"], []),
  new Emoji("🇸🇻", "El Salvador", "Flags", ["el_salvador"], []),
  new Emoji("🇬🇶", "Equatorial Guinea", "Flags", ["equatorial_guinea"], []),
  new Emoji("🇪🇷", "Eritrea", "Flags", ["eritrea"], []),
  new Emoji("🇪🇪", "Estonia", "Flags", ["estonia"], []),
  new Emoji("🇪🇹", "Ethiopia", "Flags", ["ethiopia"], []),
  new Emoji("🇪🇺", "European Union", "Flags", ["eu","european_union"], []),
  new Emoji("🇫🇰", "Falkland Islands", "Flags", ["falkland_islands"], []),
  new Emoji("🇫🇴", "Faroe Islands", "Flags", ["faroe_islands"], []),
  new Emoji("🇫🇯", "Fiji", "Flags", ["fiji"], []),
  new Emoji("🇫🇮", "Finland", "Flags", ["finland"], []),
  new Emoji("🇫🇷", "France", "Flags", ["fr"], ["france","french"]),
  new Emoji("🇬🇫", "French Guiana", "Flags", ["french_guiana"], []),
  new Emoji("🇵🇫", "French Polynesia", "Flags", ["french_polynesia"], []),
  new Emoji("🇹🇫", "French Southern Territories", "Flags", ["french_southern_territories"], []),
  new Emoji("🇬🇦", "Gabon", "Flags", ["gabon"], []),
  new Emoji("🇬🇲", "Gambia", "Flags", ["gambia"], []),
  new Emoji("🇬🇪", "Georgia", "Flags", ["georgia"], []),
  new Emoji("🇩🇪", "Germany", "Flags", ["de"], ["flag","germany"]),
  new Emoji("🇬🇭", "Ghana", "Flags", ["ghana"], []),
  new Emoji("🇬🇮", "Gibraltar", "Flags", ["gibraltar"], []),
  new Emoji("🇬🇷", "Greece", "Flags", ["greece"], []),
  new Emoji("🇬🇱", "Greenland", "Flags", ["greenland"], []),
  new Emoji("🇬🇩", "Grenada", "Flags", ["grenada"], []),
  new Emoji("🇬🇵", "Guadeloupe", "Flags", ["guadeloupe"], []),
  new Emoji("🇬🇺", "Guam", "Flags", ["guam"], []),
  new Emoji("🇬🇹", "Guatemala", "Flags", ["guatemala"], []),
  new Emoji("🇬🇬", "Guernsey", "Flags", ["guernsey"], []),
  new Emoji("🇬🇳", "Guinea", "Flags", ["guinea"], []),
  new Emoji("🇬🇼", "Guinea-Bissau", "Flags", ["guinea_bissau"], []),
  new Emoji("🇬🇾", "Guyana", "Flags", ["guyana"], []),
  new Emoji("🇭🇹", "Haiti", "Flags", ["haiti"], []),
  new Emoji("🇭🇳", "Honduras", "Flags", ["honduras"], []),
  new Emoji("🇭🇰", "Hong Kong SAR China", "Flags", ["hong_kong"], []),
  new Emoji("🇭🇺", "Hungary", "Flags", ["hungary"], []),
  new Emoji("🇮🇸", "Iceland", "Flags", ["iceland"], []),
  new Emoji("🇮🇳", "India", "Flags", ["india"], []),
  new Emoji("🇮🇩", "Indonesia", "Flags", ["indonesia"], []),
  new Emoji("🇮🇷", "Iran", "Flags", ["iran"], []),
  new Emoji("🇮🇶", "Iraq", "Flags", ["iraq"], []),
  new Emoji("🇮🇪", "Ireland", "Flags", ["ireland"], []),
  new Emoji("🇮🇲", "Isle of Man", "Flags", ["isle_of_man"], []),
  new Emoji("🇮🇱", "Israel", "Flags", ["israel"], []),
  new Emoji("🇮🇹", "Italy", "Flags", ["it"], ["italy"]),
  new Emoji("🇯🇲", "Jamaica", "Flags", ["jamaica"], []),
  new Emoji("🇯🇵", "Japan", "Flags", ["jp"], ["japan"]),
  new Emoji("🎌", "crossed flags", "Flags", ["crossed_flags"], []),
  new Emoji("🇯🇪", "Jersey", "Flags", ["jersey"], []),
  new Emoji("🇯🇴", "Jordan", "Flags", ["jordan"], []),
  new Emoji("🇰🇿", "Kazakhstan", "Flags", ["kazakhstan"], []),
  new Emoji("🇰🇪", "Kenya", "Flags", ["kenya"], []),
  new Emoji("🇰🇮", "Kiribati", "Flags", ["kiribati"], []),
  new Emoji("🇽🇰", "Kosovo", "Flags", ["kosovo"], []),
  new Emoji("🇰🇼", "Kuwait", "Flags", ["kuwait"], []),
  new Emoji("🇰🇬", "Kyrgyzstan", "Flags", ["kyrgyzstan"], []),
  new Emoji("🇱🇦", "Laos", "Flags", ["laos"], []),
  new Emoji("🇱🇻", "Latvia", "Flags", ["latvia"], []),
  new Emoji("🇱🇧", "Lebanon", "Flags", ["lebanon"], []),
  new Emoji("🇱🇸", "Lesotho", "Flags", ["lesotho"], []),
  new Emoji("🇱🇷", "Liberia", "Flags", ["liberia"], []),
  new Emoji("🇱🇾", "Libya", "Flags", ["libya"], []),
  new Emoji("🇱🇮", "Liechtenstein", "Flags", ["liechtenstein"], []),
  new Emoji("🇱🇹", "Lithuania", "Flags", ["lithuania"], []),
  new Emoji("🇱🇺", "Luxembourg", "Flags", ["luxembourg"], []),
  new Emoji("🇲🇴", "Macau SAR China", "Flags", ["macau"], []),
  new Emoji("🇲🇰", "Macedonia", "Flags", ["macedonia"], []),
  new Emoji("🇲🇬", "Madagascar", "Flags", ["madagascar"], []),
  new Emoji("🇲🇼", "Malawi", "Flags", ["malawi"], []),
  new Emoji("🇲🇾", "Malaysia", "Flags", ["malaysia"], []),
  new Emoji("🇲🇻", "Maldives", "Flags", ["maldives"], []),
  new Emoji("🇲🇱", "Mali", "Flags", ["mali"], []),
  new Emoji("🇲🇹", "Malta", "Flags", ["malta"], []),
  new Emoji("🇲🇭", "Marshall Islands", "Flags", ["marshall_islands"], []),
  new Emoji("🇲🇶", "Martinique", "Flags", ["martinique"], []),
  new Emoji("🇲🇷", "Mauritania", "Flags", ["mauritania"], []),
  new Emoji("🇲🇺", "Mauritius", "Flags", ["mauritius"], []),
  new Emoji("🇾🇹", "Mayotte", "Flags", ["mayotte"], []),
  new Emoji("🇲🇽", "Mexico", "Flags", ["mexico"], []),
  new Emoji("🇫🇲", "Micronesia", "Flags", ["micronesia"], []),
  new Emoji("🇲🇩", "Moldova", "Flags", ["moldova"], []),
  new Emoji("🇲🇨", "Monaco", "Flags", ["monaco"], []),
  new Emoji("🇲🇳", "Mongolia", "Flags", ["mongolia"], []),
  new Emoji("🇲🇪", "Montenegro", "Flags", ["montenegro"], []),
  new Emoji("🇲🇸", "Montserrat", "Flags", ["montserrat"], []),
  new Emoji("🇲🇦", "Morocco", "Flags", ["morocco"], []),
  new Emoji("🇲🇿", "Mozambique", "Flags", ["mozambique"], []),
  new Emoji("🇲🇲", "Myanmar (Burma)", "Flags", ["myanmar"], ["burma"]),
  new Emoji("🇳🇦", "Namibia", "Flags", ["namibia"], []),
  new Emoji("🇳🇷", "Nauru", "Flags", ["nauru"], []),
  new Emoji("🇳🇵", "Nepal", "Flags", ["nepal"], []),
  new Emoji("🇳🇱", "Netherlands", "Flags", ["netherlands"], []),
  new Emoji("🇳🇨", "New Caledonia", "Flags", ["new_caledonia"], []),
  new Emoji("🇳🇿", "New Zealand", "Flags", ["new_zealand"], []),
  new Emoji("🇳🇮", "Nicaragua", "Flags", ["nicaragua"], []),
  new Emoji("🇳🇪", "Niger", "Flags", ["niger"], []),
  new Emoji("🇳🇬", "Nigeria", "Flags", ["nigeria"], []),
  new Emoji("🇳🇺", "Niue", "Flags", ["niue"], []),
  new Emoji("🇳🇫", "Norfolk Island", "Flags", ["norfolk_island"], []),
  new Emoji("🇲🇵", "Northern Mariana Islands", "Flags", ["northern_mariana_islands"], []),
  new Emoji("🇰🇵", "North Korea", "Flags", ["north_korea"], []),
  new Emoji("🇳🇴", "Norway", "Flags", ["norway"], []),
  new Emoji("🇴🇲", "Oman", "Flags", ["oman"], []),
  new Emoji("🇵🇰", "Pakistan", "Flags", ["pakistan"], []),
  new Emoji("🇵🇼", "Palau", "Flags", ["palau"], []),
  new Emoji("🇵🇸", "Palestinian Territories", "Flags", ["palestinian_territories"], []),
  new Emoji("🇵🇦", "Panama", "Flags", ["panama"], []),
  new Emoji("🇵🇬", "Papua New Guinea", "Flags", ["papua_new_guinea"], []),
  new Emoji("🇵🇾", "Paraguay", "Flags", ["paraguay"], []),
  new Emoji("🇵🇪", "Peru", "Flags", ["peru"], []),
  new Emoji("🇵🇭", "Philippines", "Flags", ["philippines"], []),
  new Emoji("🇵🇳", "Pitcairn Islands", "Flags", ["pitcairn_islands"], []),
  new Emoji("🇵🇱", "Poland", "Flags", ["poland"], []),
  new Emoji("🇵🇹", "Portugal", "Flags", ["portugal"], []),
  new Emoji("🇵🇷", "Puerto Rico", "Flags", ["puerto_rico"], []),
  new Emoji("🇶🇦", "Qatar", "Flags", ["qatar"], []),
  new Emoji("🇷🇪", "Réunion", "Flags", ["reunion"], []),
  new Emoji("🇷🇴", "Romania", "Flags", ["romania"], []),
  new Emoji("🇷🇺", "Russia", "Flags", ["ru"], ["russia"]),
  new Emoji("🇷🇼", "Rwanda", "Flags", ["rwanda"], []),
  new Emoji("🇧🇱", "St. Barthélemy", "Flags", ["st_barthelemy"], []),
  new Emoji("🇸🇭", "St. Helena", "Flags", ["st_helena"], []),
  new Emoji("🇰🇳", "St. Kitts & Nevis", "Flags", ["st_kitts_nevis"], []),
  new Emoji("🇱🇨", "St. Lucia", "Flags", ["st_lucia"], []),
  new Emoji("🇵🇲", "St. Pierre & Miquelon", "Flags", ["st_pierre_miquelon"], []),
  new Emoji("🇻🇨", "St. Vincent & Grenadines", "Flags", ["st_vincent_grenadines"], []),
  new Emoji("🇼🇸", "Samoa", "Flags", ["samoa"], []),
  new Emoji("🇸🇲", "San Marino", "Flags", ["san_marino"], []),
  new Emoji("🇸🇹", "São Tomé & Príncipe", "Flags", ["sao_tome_principe"], []),
  new Emoji("🇸🇦", "Saudi Arabia", "Flags", ["saudi_arabia"], []),
  new Emoji("🇸🇳", "Senegal", "Flags", ["senegal"], []),
  new Emoji("🇷🇸", "Serbia", "Flags", ["serbia"], []),
  new Emoji("🇸🇨", "Seychelles", "Flags", ["seychelles"], []),
  new Emoji("🇸🇱", "Sierra Leone", "Flags", ["sierra_leone"], []),
  new Emoji("🇸🇬", "Singapore", "Flags", ["singapore"], []),
  new Emoji("🇸🇽", "Sint Maarten", "Flags", ["sint_maarten"], []),
  new Emoji("🇸🇰", "Slovakia", "Flags", ["slovakia"], []),
  new Emoji("🇸🇮", "Slovenia", "Flags", ["slovenia"], []),
  new Emoji("🇸🇧", "Solomon Islands", "Flags", ["solomon_islands"], []),
  new Emoji("🇸🇴", "Somalia", "Flags", ["somalia"], []),
  new Emoji("🇿🇦", "South Africa", "Flags", ["south_africa"], []),
  new Emoji("🇬🇸", "South Georgia & South Sandwich Islands", "Flags", ["south_georgia_south_sandwich_islands"], []),
  new Emoji("🇰🇷", "South Korea", "Flags", ["kr"], ["korea"]),
  new Emoji("🇸🇸", "South Sudan", "Flags", ["south_sudan"], []),
  new Emoji("🇪🇸", "Spain", "Flags", ["es"], ["spain"]),
  new Emoji("🇱🇰", "Sri Lanka", "Flags", ["sri_lanka"], []),
  new Emoji("🇸🇩", "Sudan", "Flags", ["sudan"], []),
  new Emoji("🇸🇷", "Suriname", "Flags", ["suriname"], []),
  new Emoji("🇸🇿", "Swaziland", "Flags", ["swaziland"], []),
  new Emoji("🇸🇪", "Sweden", "Flags", ["sweden"], []),
  new Emoji("🇨🇭", "Switzerland", "Flags", ["switzerland"], []),
  new Emoji("🇸🇾", "Syria", "Flags", ["syria"], []),
  new Emoji("🇹🇼", "Taiwan", "Flags", ["taiwan"], []),
  new Emoji("🇹🇯", "Tajikistan", "Flags", ["tajikistan"], []),
  new Emoji("🇹🇿", "Tanzania", "Flags", ["tanzania"], []),
  new Emoji("🇹🇭", "Thailand", "Flags", ["thailand"], []),
  new Emoji("🇹🇱", "Timor-Leste", "Flags", ["timor_leste"], []),
  new Emoji("🇹🇬", "Togo", "Flags", ["togo"], []),
  new Emoji("🇹🇰", "Tokelau", "Flags", ["tokelau"], []),
  new Emoji("🇹🇴", "Tonga", "Flags", ["tonga"], []),
  new Emoji("🇹🇹", "Trinidad & Tobago", "Flags", ["trinidad_tobago"], []),
  new Emoji("🇹🇳", "Tunisia", "Flags", ["tunisia"], []),
  new Emoji("🇹🇷", "Turkey", "Flags", ["tr"], ["turkey"]),
  new Emoji("🇹🇲", "Turkmenistan", "Flags", ["turkmenistan"], []),
  new Emoji("🇹🇨", "Turks & Caicos Islands", "Flags", ["turks_caicos_islands"], []),
  new Emoji("🇹🇻", "Tuvalu", "Flags", ["tuvalu"], []),
  new Emoji("🇺🇬", "Uganda", "Flags", ["uganda"], []),
  new Emoji("🇺🇦", "Ukraine", "Flags", ["ukraine"], []),
  new Emoji("🇦🇪", "United Arab Emirates", "Flags", ["united_arab_emirates"], []),
  new Emoji("🇬🇧", "United Kingdom", "Flags", ["gb","uk"], ["flag","british"]),
  new Emoji("🇺🇸", "United States", "Flags", ["us"], ["flag","united","america"]),
  new Emoji("🇻🇮", "U.S. Virgin Islands", "Flags", ["us_virgin_islands"], []),
  new Emoji("🇺🇾", "Uruguay", "Flags", ["uruguay"], []),
  new Emoji("🇺🇿", "Uzbekistan", "Flags", ["uzbekistan"], []),
  new Emoji("🇻🇺", "Vanuatu", "Flags", ["vanuatu"], []),
  new Emoji("🇻🇦", "Vatican City", "Flags", ["vatican_city"], []),
  new Emoji("🇻🇪", "Venezuela", "Flags", ["venezuela"], []),
  new Emoji("🇻🇳", "Vietnam", "Flags", ["vietnam"], []),
  new Emoji("🇼🇫", "Wallis & Futuna", "Flags", ["wallis_futuna"], []),
  new Emoji("🇪🇭", "Western Sahara", "Flags", ["western_sahara"], []),
  new Emoji("🇾🇪", "Yemen", "Flags", ["yemen"], []),
  new Emoji("🇿🇲", "Zambia", "Flags", ["zambia"], []),
  new Emoji("🇿🇼", "Zimbabwe", "Flags", ["zimbabwe"], [])
];
